import React, { useState } from 'react';

import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Grid,
    Typography,
    Box,
    IconButton,
    Card,
    CardContent,
    Collapse,
    Container,
    Modal
} from '@mui/material';

import {
    ExpandLessOutlined,
    ExpandMoreOutlined,
    LocalShippingOutlined,
    PlaceOutlined,
    NearMeOutlined
} from "@mui/icons-material";

import {
    FiPackage
} from "react-icons/fi";

import DownloadButton from '../../../Shared/DownloadButton';

export default function TrackingItem(props) {
    const { onClose, selectedValue, open, title, items } = props;

    const [ cards, setCards ] = useState({
        showHistory : true,
        showPackageDimensions : false,
        showOrigin : false,
        showDestination : false
    });

    const userPermissions = JSON.parse( sessionStorage.getItem( 'permissions' ) );
    const tracking = ( items['tracking'] !== undefined ) ? items['tracking'] : [];
    const waybill = ( items['waybill'] !== undefined ) ? items['waybill'] : '' ;

    const handleClose = () => {
        setCards({
            showHistory : true,
            showPackageDimensions : false,
            showOrigin : false,
            showDestination : false
        });
        onClose(selectedValue);
    };

    const showCard = key => {
        setCards({
            showHistory : false,
            showPackageDimensions : false,
            showOrigin : false,
            showDestination : false,
            ...key
        });
    };
    
    return (
        <Modal 
            open = { open } 
            onClose = { handleClose }
        >
            <Container 
                maxWidth = "md"
            >
                <Paper 
                    elevation = {10} 
                    sx = {{ marginY : 22 }}
                >
                    <div
                        style = {{
                            display : "flex",
                            justifyContent : "space-between",
                            padding : "14px"
                        }}
                    >
                        <Typography
                            fontSize = "24px"
                            fontWeight = "400"
                        >
                            {title}
                        </Typography>

                        {
                            ( 
                                userPermissions["role"] === "Admin" &&
                                tracking.length !== 0 &&
                                waybill["drive_waybill_id"] !== null
                            ) ?
                                <DownloadButton
                                    type="waybill" identifier={waybill["numero"]}
                                />
                            : <div></div>
                        }
                    </div>
                
                    <hr 
                        style={{
                            margin : 0,
                            size : "1px",
                            color : "#ff4500"
                        }}
                    />

                    {
                        tracking.length !== 0 ?
                            <>
                                <Card
                                    variant = "outlined"
                                >
                                    <Box>
                                        <Grid
                                            container
                                        >
                                            <Grid
                                                item
                                                xs = { 11 }
                                                display = "flex"
                                                alignItems = "center"
                                                justifyContent = "center"
                                            >
                                                <Typography
                                                    fontWeight = "700"
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "center"
                                                >
                                                    <LocalShippingOutlined 
                                                        sx = {{
                                                            color : "#ff4500",
                                                            fontSize : "2rem"
                                                        }}
                                                    />
                                                    Rastreos de { waybill["numero"] }
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs = { 1 }
                                                display = "flex"
                                                alignItems = "center"
                                                justifyContent = "end"
                                            >
                                                <IconButton
                                                    onClick = { () => showCard({ showHistory : !cards["showHistory"] }) }
                                                    sx = {{ 
                                                        color : "#ff4500"
                                                    }}
                                                >
                                                    {
                                                        cards["showHistory"] ?
                                                            <ExpandMoreOutlined 
                                                                fontSize = "large"
                                                            />
                                                        :
                                                            <ExpandLessOutlined 
                                                                fontSize = "large"
                                                            />
                                                    }
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Collapse
                                        in = { cards["showHistory"] }
                                        timeout = "auto"
                                        unmountOnExit
                                    >
                                        <CardContent
                                            sx = {{
                                                padding : 0
                                            }}
                                        >
                                            <TableContainer>
                                                <Table sx={{ width: 900 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Estatus</TableCell>
                                                            <TableCell align="right">Descripcion</TableCell>
                                                            <TableCell align="right">Fecha</TableCell>
                                                            <TableCell align="right">Hora</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tracking.map((item,id) => (
                                                            (item.length===0)?"":
                                                                <TableRow
                                                                    key={id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">{item.proceso.name}</TableCell>
                                                                    <TableCell align="right">{item.descripcion}</TableCell>
                                                                    <TableCell align="right">{(new Date(item.created_at)).toLocaleDateString()}</TableCell>
                                                                    <TableCell align="right">{(new Date(item.created_at)).toLocaleTimeString()}</TableCell>
                                                                </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                    
                                <hr 
                                    style={{
                                        margin : 0,
                                        size : "1px",
                                        color : "#ff4500"
                                    }}
                                />

                                <Card
                                    variant = "outlined"
                                >
                                    <Box>
                                        <Grid
                                            container
                                        >
                                            <Grid
                                                item
                                                xs = { 11 }
                                                display = "flex"
                                                alignItems = "center"
                                                justifyContent = "center"
                                            >
                                                <Typography
                                                    fontWeight = "700"
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "center"
                                                >
                                                    <FiPackage 
                                                        color = "#ff4500"
                                                        size = "2rem"
                                                    />
                                                    Dimensiones del paquete
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs = { 1 }
                                                display = "flex"
                                                alignItems = "center"
                                                justifyContent = "end"
                                            >
                                                <IconButton
                                                    onClick = { () => showCard({ showPackageDimensions : !cards["showPackageDimensions"] }) }
                                                    sx = {{ 
                                                        color : "#ff4500"
                                                    }}
                                                >
                                                    {
                                                        cards["showPackageDimensions"] ?
                                                            <ExpandMoreOutlined 
                                                                fontSize = "large"
                                                            />
                                                        :
                                                            <ExpandLessOutlined 
                                                                fontSize = "large"
                                                            />
                                                    }
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                                    <Collapse
                                        in = { cards["showPackageDimensions"] }
                                        timeout = "auto"
                                        unmountOnExit
                                    >
                                        <CardContent
                                            sx = {{
                                                padding : 0
                                            }}
                                        >
                                            <Grid 
                                                container
                                            >
                                                <Grid
                                                    item
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "space-around"
                                                    fontWeight = "700"
                                                    padding={2.1} 
                                                    xs = { 6 }
                                                >
                                                    KG:
                                                    <Typography
                                                        sx = {{
                                                            fontWeight : "400"
                                                        }}
                                                    >
                                                        { waybill["kg"] }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "space-around"
                                                    fontWeight = "700"
                                                    padding={2.1} 
                                                    xs = { 6 }
                                                >
                                                    Ancho:
                                                    <Typography
                                                        sx = {{
                                                            fontWeight : "400"
                                                        }}
                                                    >
                                                        { waybill["ancho"] }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "space-around"
                                                    fontWeight = "700"
                                                    padding={2.1} 
                                                    xs = { 6 }
                                                >
                                                    Alto:
                                                    <Typography
                                                        sx = {{
                                                            fontWeight : "400"
                                                        }}
                                                    >
                                                        { waybill["alto"] }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    display = "flex"
                                                    alignItems = "center"
                                                    justifyContent = "space-around"
                                                    fontWeight = "700"
                                                    padding={2.1} 
                                                    xs = { 6 }
                                                >
                                                    Largo:
                                                    <Typography
                                                        sx = {{
                                                            fontWeight : "400"
                                                        }}
                                                    >
                                                        { waybill["largo"] }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Card>

                                <hr 
                                    style={{
                                        margin : 0,
                                        size : "1px",
                                        color : "#ff4500"
                                    }}
                                />

                                <Grid 
                                    container
                                >
                                    <Grid item xs = {6}>
                                        <Card
                                            variant = "outlined"
                                        >
                                            <Box>
                                                <Grid
                                                    container
                                                >
                                                    <Grid
                                                        item
                                                        xs = { 11 }
                                                        display = "flex"
                                                        alignItems = "center"
                                                        justifyContent = "center"
                                                    >
                                                        <Typography
                                                            fontWeight = "700"
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "center"
                                                        >
                                                            <PlaceOutlined 
                                                                sx = {{
                                                                    color : "#ff4500",
                                                                    fontSize : "2rem"
                                                                }}
                                                            />
                                                            Origen
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs = { 1 }
                                                        display = "flex"
                                                        alignItems = "center"
                                                        justifyContent = "end"
                                                    >
                                                        <IconButton
                                                            onClick = { () => showCard({ showOrigin : !cards["showOrigin"] }) }
                                                            sx = {{ 
                                                                color : "#ff4500"
                                                            }}
                                                        >
                                                            {
                                                                cards["showOrigin"] ?
                                                                    <ExpandMoreOutlined 
                                                                        fontSize = "large"
                                                                    />
                                                                :
                                                                    <ExpandLessOutlined 
                                                                        fontSize = "large"
                                                                    />
                                                            }
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            
                                            <Collapse
                                                in = { cards["showOrigin"] }
                                                timeout = "auto"
                                                unmountOnExit
                                            >
                                                <CardContent
                                                    sx = {{
                                                        padding : 0
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1}
                                                            xs = { 12 }
                                                        >
                                                            Ciudad:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["origenMunicipio"] }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1} 
                                                            xs = { 12 }
                                                        >
                                                            Estado:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["origenEstado"] }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1} 
                                                            xs = { 12 }
                                                        >
                                                            C.P:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["origenCP"] }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Collapse>
                                        </Card>
                                    </Grid>

                                    <Grid 
                                        item 
                                        xs = {6}
                                        borderLeft = "2px solid #ff4500"
                                    >
                                        <Card
                                            variant = "outlined"
                                        >
                                            <Box>
                                                <Grid
                                                    container
                                                >
                                                    <Grid
                                                        item
                                                        xs = { 11 }
                                                        display = "flex"
                                                        alignItems = "center"
                                                        justifyContent = "center"
                                                    >
                                                        <Typography
                                                            fontWeight = "700"
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "center"
                                                        >
                                                            <NearMeOutlined 
                                                                sx = {{
                                                                    color : "#ff4500",
                                                                    fontSize : "2rem"
                                                                }}
                                                            />
                                                            Destino
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs = { 1 }
                                                        display = "flex"
                                                        alignItems = "center"
                                                        justifyContent = "end"
                                                    >
                                                        <IconButton
                                                            onClick = { () => showCard({ showDestination : !cards["showDestination"] }) }
                                                            sx = {{ 
                                                                color : "#ff4500"
                                                            }}
                                                        >
                                                            {
                                                                cards["showDestination"] ?
                                                                    <ExpandMoreOutlined 
                                                                        fontSize = "large"
                                                                    />
                                                                :
                                                                    <ExpandLessOutlined 
                                                                        fontSize = "large"
                                                                    />
                                                            }
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Collapse
                                                in = { cards["showDestination"] }
                                                timeout = "auto"
                                                unmountOnExit
                                            >
                                                <CardContent
                                                    sx = {{
                                                        padding : 0
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1} 
                                                            xs = { 12 }
                                                        >
                                                            Ciudad:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["destinoMunicipio"] }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1} 
                                                            xs = { 12 }
                                                        >
                                                            Estado:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["destinoEstado"] }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            display = "flex"
                                                            alignItems = "center"
                                                            justifyContent = "space-around"
                                                            fontWeight = "700"
                                                            padding={2.1}
                                                            xs = { 12 }
                                                        >
                                                            C.P:
                                                            <Typography
                                                                sx = {{
                                                                    fontWeight : "400"
                                                                }}
                                                            >
                                                                { waybill["destinoCP"] }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Collapse>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <div 
                                style = {{
                                    textAlign : "center",
                                    fontWeight : "400",
                                    padding : "20px"
                                }}
                            >
                                La guía <span style={{ fontWeight : "700" }}>{ waybill["numero"] }</span> no fue encontrada.
                            </div>
                    }
                </Paper>
            </Container>
        </Modal>
    );
}

import React , { useState } from 'react';
import {IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { downloadTicket } from './DashboardSharedFunctions';

export default function DownloadButton(props) {
    const { type, identifier } = props;

    /* Estados */
    const [ waitAnResult , setWaitAnResult ] = useState(false);
    const [ downloadingTicket, setDownloadingTicket ] = useState(false);

    const handleClick = () => {
        if (type === 'waybill') {
          console.log('Descargar waybill:', identifier);
          downloadingWaybill()
        } else if (type === 'ticket') {
          console.log('Descargar ticket:', identifier);
          downloadTicket(setDownloadingTicket, identifier)
        }
    };
      
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
    }

    /* Funcion para descargar la guia */
    const downloadingWaybill = async () => {
        setWaitAnResult(true);

        await fetch(`${envConfig.url}/api/v2/waybills/download/${identifier}`, {
            method : "GET",
            headers : {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Cache-Control': 'no-cache'
            },
        } )
            .then( res => res.blob() )
            .then( blob => {
                const download = require('downloadjs');
                download( blob , "Guia_"+identifier+"_KL_Entregable.pdf", "application/pdf");
            } )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );
        setWaitAnResult(false);
    }

    // const downloadingTicket = async () => {
    //     setWaitAnResult(true);

    //     await fetch(`${envConfig.url}/api/v2/cart/tickets/${identifier}`, {
    //         method : "GET",
    //         headers : {
    //             "Content-Type": "application/json",
    //             'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
    //             'Cache-Control': 'no-cache'
    //         },
    //     } )
    //         .then( res => res.blob() )
    //         .then( blob => {
    //             const download = require('downloadjs');
    //             download( blob , "Guia.pdf", "application/pdf");
    //         } )
    //         .catch( error => alert( 'Algo ocurrio :C ' + error ) );
    //     setWaitAnResult(false);
    // }

    /* Retornando el componente */
    return (
        <IconButton 
            onClick={ handleClick }
            sx={{
                backgroundColor: type === 'waybill' ? "#0227e2" : "#ff5722",
                color : "#ffffff",
                width : "35px",
                height : "35px",
                borderRadius: 2,
                border: "2px solid #0227e2",
                "&:hover" : {
                    backgroundColor : "#011ca4",
                    color : "#dcdcdc",
                    border: "2px solid #011ca4",
                }
            }}
            disabled = { type === 'ticket' ? downloadingTicket : waitAnResult }
            title="Descargar guia"
        >
            <Download fontSize = "small"/>
        </IconButton>
    );
}
import { BusinessOutlined, InfoOutlined, Inventory2Outlined, NearMeOutlined, ShoppingBasketOutlined, ShoppingCartOutlined, TollOutlined, Info, Numbers, Business, AccessTime, AddShoppingCart, ReceiptLongOutlined, CategoryOutlined, LocalShippingOutlined, FileDownloadOutlined, PlaceOutlined } from '@mui/icons-material'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Tooltip, Typography, IconButton, Card, CardContent } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import ShoppingCartContext from './ShoppingCartContext'
import { downloadTicket } from '../../Shared'
import { QuoteDetails } from '../Cotizaciones/QuotesSharedComponents'
import { useTheme } from '@emotion/react'
import DownloadButton from '../../Shared/DownloadButton'

export function ItemCollapse({ data, index, type, noDetails, isDeletable = true }) {
  const { removeItem } = useContext(ShoppingCartContext)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  return (
    <>
      <Accordion>
        <AccordionSummary>
          {
            {
              products: <ProductItem data={data} chipDetails={true} />,
              waybills: <WaybillItem data={data} chipDetails={true}/>,
              deliveries: <DeliveryItem data={data} chipDetails={true}/>
            }[type]
          }
        </AccordionSummary>
        {!noDetails && (
          <AccordionDetails>
            {
              {
                products: <ProductDetails data={data} />,
                waybills: <WaybillDetails data={data} />,
                deliveries: <DeliveryDetails data={data} />
              }[type]
            }
          </AccordionDetails>
        )}
        {isDeletable && ( 
          <AccordionActions>
            <Button
              variant='contained'
              color='error'
              onClick={() => setShowDeleteDialog(true)}
            >
              Eliminar
            </Button>
          </AccordionActions>
        )}
      </Accordion>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Eliminar {{ products: 'producto', waybills: 'cotización', deliveries: 'entrega' }[type]}</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>¿Seguro que desea eliminar este item? Esta acción no se puede deshacer</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              setShowDeleteDialog(false)
              removeItem(type, index)
            }}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function WaybillItem({ data , chipDetails=false}) {
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))
  
  return (
    <Stack
      useFlexGap
      flexWrap='wrap'
      sx={{ width: '100%' }}
      spacing={1}
    >
      <Stack
        direction='row'
        useFlexGap
        flexWrap='wrap'
      >
        <Typography sx={{ fontSize: 12.5 }}>
          {`${data.origenEmpresa ? data.origenEmpresa + ' - ' : ''} ${data.origenPersona}`}
          {' > '}
          {`${data.destinoEmpresa ? data.destinoEmpresa + ' - ' : ''} ${data.destinoPersona}`}
        </Typography>
      </Stack>
      <Stack
        useFlexGap
        flexWrap='wrap'
        sx={{ width: '100%' }}
        direction='row'
      >
        <Stack
            useFlexGap
            flexWrap='wrap'
            direction='row'
            spacing={0.4}
            justifyContent='start'
            sx={{ width: '80%' }}
        >
          <Tooltip title='Municipio y estado de destino'>
            <Chip
              icon={<NearMeOutlined sx={{ fontiSize: 18 }}/>}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{`${data.destinoMunicipio}, ${data.destinoEstado}`}</Typography>}
            />
          </Tooltip>

          <Tooltip title='Servicio'>
            <Chip
              icon={<LocalShippingOutlined sx={{ fontiSize: 18 }}/>}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{data.tipo_servicio_nombre}</Typography>}
            />
          </Tooltip>

          <Tooltip title='Quién realiza el pago'>
            <Chip
              icon={<ShoppingCartOutlined sx={{ fontiSize: 18 }}/>}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{`Pago en ${{ 0: 'origen', 1: 'destino' }[data.pago_en_destino]}`}</Typography>}
            />
          </Tooltip>

          {chipDetails && (
            <Tooltip title='Ver Detalles'>
              <Chip 
                  icon={<Info sx={{ fontSize: 18 }}/>}
                  variant='outlined'
                  label={<Typography sx={{ fontSize: 10.5 }}>Ver Detalles</Typography>}
              />
            </Tooltip>
          )}
        </Stack>

        {permissions['role'] !== 'SubCliente' && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='right'
            spacing={0.1}
            sx={{ 
                width: '20%',
                height: '100%' 
            }}
          >
            <Typography
              fontSize={24}
              fontWeight={700}
            >
              <span style={{ color: '#007a03' }}>$</span>{data?.precio_final ? parseFloat(data.precio_final).toFixed(2) : '??'}
            </Typography>
            {data.numero && <DownloadButton type="waybill" identifier={data?.numero} />}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export function WaybillDetails({ data }) {
  return <QuoteDetails waybill={data} />
}

export function ProductItem({ data, chipDetails = false }) {
  return (
    <Stack
      useFlexGap
      flexWrap='wrap'
      sx={{ width: '100%' }}
      spacing={1}
    >
      <Typography sx={{ fontSize: 12.5 }}>{data.name}</Typography>

      <Stack
        useFlexGap
        flexWrap='wrap'
        sx={{ width: '100%' }}
        direction='row'
      >
        <Stack
            useFlexGap
            flexWrap='wrap'
            direction='row'
            spacing={0.4}
            justifyContent='start'
            sx={{ width: '80%' }}
        >
          <Tooltip title='Cantidad'>
            <Chip
              icon={<ShoppingBasketOutlined sx={{ fontSize: 18 }} />}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{data.quantity}</Typography>}
            />
          </Tooltip>

          <Tooltip title='Precio unitario'>
            <Chip
              icon={<TollOutlined sx={{ fontSize: 18 }} />}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{`$${data.amount}`}</Typography>}
            />
          </Tooltip>

          {chipDetails && (
            <Tooltip title='Ver Detalles'>
              <Chip 
                  icon={<Info sx={{ fontSize: 18 }}/>}
                  variant='outlined'
                  label={<Typography sx={{ fontSize: 10.5 }}>Ver Detalles</Typography>}
              />
            </Tooltip>
          )}
        </Stack>

        {data.total !== undefined && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='right'
            spacing={0.1}
            sx={{ 
                width: '20%',
                height: '100%' 
            }}
          >
            <Typography
              fontSize={24}
              fontWeight={700}
            >
              <span style={{ color: '#007a03' }}>$</span>{Number(data.total).toFixed(2)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export function ProductDetails({ data }) {
  const { name, description, quantity, amount, total, waybill } = data
  return (
    <SectionCard
      title='Más información'
      icon={<InfoOutlined sx={{ fontSize: 18 }} />}
    >
      <Typography variant='body1'>Producto: {name}</Typography>
      <Typography variant='body1'>Cantidad: {quantity}</Typography>
      <Typography variant='body1'>Precio unitario: ${Number(amount).toFixed(2)}</Typography>
      <Typography variant='body1'>Total: ${Number(total).toFixed(2)}</Typography>
      {description !== '' && <Typography variant='body1'>Información adicional: {description}</Typography>}
      {![null, undefined].includes(waybill) && <Typography>Cotización o entrega vinculada: {waybill.name ?? waybill}</Typography>}
    </SectionCard>
  )
}

export function DeliveryItem({ data, chipDetails=false }) {
  useEffect(() => {
    console.log("DeliveryItem");
    console.log(data);
  }, [])
  return (
    <Stack
      useFlexGap
      flexWrap='wrap'
      sx={{ width: '100%' }}
      spacing={1}
    >
      <Typography sx={{ fontSize: 12.5 }}>{data.numero}</Typography>

      <Stack
        useFlexGap
        flexWrap='wrap'
        sx={{ width: '100%' }}
        direction='row'
      >
        <Stack
            useFlexGap
            flexWrap='wrap'
            direction='row'
            spacing={0.4}
            justifyContent='start'
            sx={{ width: '80%' }}
        >
          {data.carrier!==undefined && data.carrier.name!==undefined && (
            <Tooltip title='Proveedor'>
              <Chip
                icon={<BusinessOutlined sx={{ fontSize: 18 }} />}
                variant='outlined'
                label={<Typography sx={{ fontSize: 10.5 }}>{data.carrier.name}</Typography>}
              />
            </Tooltip>
          )}

          {data.service!==undefined && data.service.name!==undefined && (
            <Tooltip title='Servicio'>
              <Chip
                icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
                variant='outlined'
                label={<Typography sx={{ fontSize: 10.5 }}>{data.service.name}</Typography>}
              />
            </Tooltip>
          )}

          {chipDetails && (
            <Tooltip title='Ver Detalles'>
              <Chip 
                  icon={<Info sx={{ fontSize: 18 }}/>}
                  variant='outlined'
                  label={<Typography sx={{ fontSize: 10.5 }}>Ver Detalles</Typography>}
              />
            </Tooltip>
          )}
        </Stack>

        {data.costoDeEnvio!==undefined && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='right'
            spacing={0.1}
            sx={{ 
                width: '20%',
                height: '100%' 
            }}
          >
            <Typography
              fontSize={24}
              fontWeight={700}
            >
              <span style={{ color: '#007a03' }}>$</span>{Number(data.precio_final).toFixed(2)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export function DeliveryDetails({ data }) {
  const { numero, origenEmpresa, destinoEmpresa, origenPersona, destinoPersona, origenCP, destinoCP, kg, ancho, alto, largo, carrier, service, costoDeEnvio, precio_final, primaSeguro, tarifaBase, sobrepeso, reexpedicion, irregular, cod, otros } = data

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <SectionCard
          title='Origen'
          icon={<PlaceOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            {!['', null, undefined].includes(origenEmpresa) && <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Empresa: `}</span>{origenEmpresa}</Typography>}
            {!['', null, undefined].includes(origenPersona) && <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Nombre: `}</span>{origenPersona}</Typography>}
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`C.P: `}</span>{origenCP}</Typography>
          </Stack>
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <SectionCard
          title='Destino'
          icon={<NearMeOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            {!['', null, undefined].includes(destinoEmpresa) && <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Empresa: `}</span>{destinoEmpresa}</Typography>}
            {!['', null, undefined].includes(destinoPersona) && <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Nombre: `}</span>{destinoPersona}</Typography>}
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`C.P: `}</span>{destinoCP}</Typography>
          </Stack>
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <SectionCard
          title='Paquete'
          icon={<Inventory2Outlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Dimensiones: `}</span>{`${ancho}cm x ${alto}cm x ${largo}cm`}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Peso: `}</span>{`${kg} kg`}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Tarifa base: `}</span>${Number(tarifaBase).toFixed(2)}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Sobrepeso: `}</span>{Number(sobrepeso).toFixed(2)}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Costo del envío:`}</span>${Number(precio_final).toFixed(2)}</Typography>
          </Stack>
        </SectionCard>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <SectionCard
          title='Envío'
          icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
        >
          <Stack>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Guía: `}</span>{numero}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Proveedor: `}</span>{carrier?.name}</Typography>
            <Typography variant='body1'><span style={{ fontWeight: '700' }}>{`Servicio: `}</span>{service?.name}</Typography>
          </Stack>
        </SectionCard>
      </Grid>

      {(primaSeguro !== 0 || reexpedicion !== 0 || irregular !== 0 || cod !== 0 || otros !== 0) && (
        <Grid
          item
          xs={12}
        >
          <SectionCard
            title='Otros cargos'
            icon={<TollOutlined sx={{ fontSize: 18 }} />}
          >
            {primaSeguro !== 0 && <Typography variant='body1'>Prima de seguro: ${Number(primaSeguro).toFixed(2)}</Typography>}
            {reexpedicion !== 0 && <Typography variant='body1'>Reexpedición: ${Number(reexpedicion).toFixed(2)}</Typography>}
            {irregular !== 0 && <Typography variant='body1'>Irregular: ${Number(irregular).toFixed(2)}</Typography>}
            {cod !== 0 && <Typography variant='body1'>COD: ${Number(cod).toFixed(2)}</Typography>}
            {otros !== 0 && <Typography variant='body1'>Otros: ${Number(otros).toFixed(2)}</Typography>}
          </SectionCard>
        </Grid>
      )}
    </Grid>
  )
}

export function PendingPayItem({ data, addPendingPayHandler, isDownloadable=true, chipDetails=false }) {

  const DownloadTicketButton = () => {
    const [downloadingTicket, setDownloadingTicket] = useState(false)

    return (
      <IconButton
        title='Descargar ticket'
        disabled={downloadingTicket}
        onClick={() => downloadTicket(setDownloadingTicket, data['uuid'], [])}
        sx={{ color: '#007FFF' }}
      >
        <FileDownloadOutlined sx={{ fontSize: 21.5 }} />
      </IconButton>
    )
  } 

  return (
    <Stack
        useFlexGap
        flexWrap='wrap'
        sx={{ width: '100%' }}
        direction='row'
    >
      <Stack
          useFlexGap
          flexWrap='wrap'
          direction='row'
          spacing={0.4}
          justifyContent='start'
          sx={{ width: '70%' }}
      >
        {data?.folio !== undefined && (

        <Tooltip title='Folio'>
          <Chip 
              icon={<Numbers sx={{ fontSize: 18 }}/>}
              variant='outlined'
              label={<Typography variant='caption'>{data?.folio}</Typography>}
          />
        </Tooltip>
        )}
        <Tooltip title='UUID'>
          <Chip 
              icon={<Numbers sx={{ fontSize: 18 }}/>}
              variant='outlined'
              label={<Typography variant='caption'>{data?.uuid}</Typography>}
          />
        </Tooltip>

        <Tooltip title='Cliente'>
          <Chip 
              icon={<Business sx={{ fontSize: 18 }}/>}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{data?.empresa.nombre_comercial}</Typography>}
          />
        </Tooltip>

        <Tooltip title='Fecha de creación'>
          <Chip 
              icon={<AccessTime sx={{ fontSize: 18 }}/>}
              variant='outlined'
              label={<Typography sx={{ fontSize: 10.5 }}>{data?.created_at}</Typography>}
          />
        </Tooltip>

        {chipDetails && (
          <Tooltip title='Ver Detalles'>
            <Chip 
                icon={<Info sx={{ fontSize: 18 }}/>}
                variant='outlined'
                label={<Typography sx={{ fontSize: 10.5 }}>Ver Detalles</Typography>}
            />
          </Tooltip>
        )}
      </Stack>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='right'
        spacing={0.02}
        sx={{ 
            width: '30%',
            height: '100%' 
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={700}
        >
          <span style={{ color: '#007a03' }}>$</span>{data?.movement.monto.toFixed(2)}
        </Typography>

        {isDownloadable && <DownloadTicketButton />}

        {typeof addPendingPayHandler === 'function' && (
          <IconButton
            color='primary'
            title='Cargar pago pendiente'
            onClick={() => addPendingPayHandler(data)}
          >
            <AddShoppingCart sx={{ fontSize: 21.5 }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export function PendingPay({ pendingPay, addPendingPayHandler, isDownloadable=true, type = '', index = 0, isDeletable = false }) {
  const { removeItem } = useContext(ShoppingCartContext)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const Section = ({ icon, title, children }) => {
    const theme = useTheme()

    return(
      <Card
          variant='outlined'
          sx={{ height: '100%' }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Stack
              direction='row'
              spacing={1}
              alignContent='center'
            >
              <Avatar sx={{ widht: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
              <Typography variant='subtitle1'>{title}</Typography>
            </Stack>
            <Stack>{children}</Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
            sx={{ 
                padding: '0px 4px'
            }}
        >
          <PendingPayItem 
            data={pendingPay}
            addPendingPayHandler={addPendingPayHandler}
            isDownloadable={isDownloadable}
            chipDetails={true}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            {pendingPay?.items.waybills.length > 0 && (
              <Section
                title='Cotizaciones'
                icon={<ReceiptLongOutlined sx={{ fontSize: 18 }} />}
              >
                {pendingPay?.items.waybills.map((item, index) => (
                    <>
                      <ItemCollapse
                        data={item}
                        index={index}
                        key={index}
                        type='waybills'
                        isDeletable={false}
                      />
                    </>
                ))}
              </Section>
            )}

            {pendingPay?.items.products.length > 0 && (
              <Section
                title='Productos'
                icon={<CategoryOutlined sx={{ fontSize: 18 }} />}
              >
                {pendingPay?.items.products.map((item, index) => (
                    <>
                      <ItemCollapse
                        data={item}
                        index={index}
                        key={index}
                        type='products'
                        isDeletable={false}
                      />
                    </>
                ))}
              </Section>
            )}

            {pendingPay?.items.deliveries.length > 0 && (
              <Section
                title='Entregas'
                icon={<LocalShippingOutlined sx={{ fontSize: 18 }} />}
              >
                {pendingPay?.items.deliveries.map((item, index) => (
                    <>
                      <ItemCollapse
                        data={item}
                        index={index}
                        key={index}
                        type='deliveries'
                        isDeletable={false}
                      />
                    </>
                ))}
              </Section>
            )}
          </Stack>
        </AccordionDetails>

        <AccordionActions
          sx={{ 
              backgroundColor: '#ff8c00' 
          }}
        > 
          {isDeletable && (
            <Button
              variant='contained'
              color='error'
              onClick={() => setShowDeleteDialog(true)}
            >
              Eliminar
            </Button>
          )}
        </AccordionActions>
      </Accordion>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Eliminar pago pendiente</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>¿Seguro que desea eliminar este item? Esta acción no se puede deshacer</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              setShowDeleteDialog(false)
              removeItem(type, index)
            }}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SectionCard = ({ title, icon, children }) => {
  const theme = useTheme()

  return (
    <Stack spacing={1}>
      <Stack
        direction='row'
        spacing={1}
        alignContent='center'
      >
        <Avatar sx={{ ancho: 28, alto: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
        <Typography variant='subtitle1'>{title}</Typography>
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  )
}

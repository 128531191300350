import { CheckCircleOutline, AddCard, HomeWorkOutlined, LocalShippingOutlined, DescriptionOutlined, AttachMoneyOutlined, AccessTimeOutlined, RequestQuote, CreditScore, Person, Key, Numbers, AccountBalanceOutlined, CreditCard } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Stack, Typography, Card, CardContent, Avatar, Tooltip, Chip, useTheme, Grid, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function FinishedPayment({ prePayment, costumerPrePayment, amount, holderName, cardNumber, expirationMonth, expirationYear, cvv2, cardType, isWithTransfer, isWithCard }) {
  return (
    <Stack
      spacing={2}
      sx={{ p: 2 }}
    >
      <Stack
        direction='row'
        spacing={2}
      >
        <CheckCircleOutline sx={{ fontSize: 50 }} />
        <Stack>
          <Typography variant='subtitle1'>¡Listo!</Typography>
          <Typography variant='body1'>Ya has terminado</Typography>
        </Stack>
      </Stack>

      {prePayment !== null && (
        <Section
          title='Paquete contratado'
          icon={<AddCard sx={{ fontSize: 18 }}/>}
          spacing={2}
        >
          <Typography 
            variant='body1'
            fontWeight='700'
          >
           Detalles del paquete:
          </Typography>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Stack
              useFlexGap
              flexWrap='wrap'
              sx={{ width: '100%' }}
              spacing={1}
            >
              <Stack
                useFlexGap
                flexWrap='wrap'
                direction='row'
                spacing={1}
              >
                <Tooltip title='Servicio'>
                  <Chip
                    icon={<LocalShippingOutlined />}
                    variant='outlined'
                    label={prePayment.servicio}
                  />
                </Tooltip>

                <Tooltip title='Empresa'>
                  <Chip
                    icon={<HomeWorkOutlined />}
                    variant='outlined'
                    label={prePayment.empresa}
                  />
                </Tooltip>

                <Tooltip title='Vigencia'>
                  <Chip 
                    icon={<AccessTimeOutlined />}
                    variant='outlined'
                    label={prePayment.vigencia_del_servicio}
                  />
                </Tooltip>

                <Tooltip title='Cantidad de guías'>
                  <Chip
                    icon={<DescriptionOutlined />}
                    variant='outlined'
                    label={`${prePayment.cantidad_de_guias} guías`}
                  />
                </Tooltip>

                <Tooltip title='Costo del paquete'>
                  <Chip
                    icon={<AttachMoneyOutlined />}
                    variant='outlined'
                    label={`${Number(prePayment.costo_del_paquete).toFixed(2)} MXN`}
                  />
                </Tooltip>

                <Tooltip title='Costo por guía'>
                  <Chip 
                    icon={<RequestQuote />}
                    variant='outlined'
                    label={`${(Number(prePayment.costo_del_paquete)/Number(prePayment.cantidad_de_guias)).toFixed(2)} MXN`}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Section>
      )}

      {isWithTransfer && (
        <Alert severity='info'>
          <AlertTitle>Estamos validando tu transferencia</AlertTitle>
          {`Tu transferencia está siendo procesada y será validada en un tiempo de máximo 72 horas. Si tienes alguna duda o necesitas asistencia puedes comunicarte con nosotros al ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}, o mediante el correo ${ process.env.REACT_APP_SALES_EMAIL }`}
        </Alert>
      )}

      {isWithCard && (
        <Section
          title='Abono de saldo mediante tarjeta a sido un exito.'
          icon={<CreditScore sx={{ fontSize: 18 }}/>}
          spacing={2}
        >
          <Stack
            direction='column'
            spacing={1}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Stack
                  direction='column'
                  spacing={1}
                >
                  <Typography
                    variant='body1'
                    fontWeight='700'
                  >
                    Resumen del abono:
                  </Typography>

                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Stack
                      useFlexGap
                      flexWrap='wrap'
                      sx={{ width: '100%' }}
                      spacing={1}
                    >
                      <Stack
                        useFlexGap
                        flexWrap='wrap'
                        direction='row'
                        spacing={1}
                      >
                        <Tooltip title='Tipo de tarjeta'>
                          <Chip
                            icon={<CreditCard />}
                            variant='outlined'
                            label={
                              <Box 
                                component='img'
                                src={{ 'Visa': require('../../../assets/paymentBrands/visa.webp') }[cardType]}
                                width='40px'
                                height='30px'
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip title='Saldo abonado'>
                          <Chip 
                            icon={<AccountBalanceOutlined />}
                            variant='outlined'
                            label={`$${amount}`}
                            sx={{
                              fontWeight: '700',
                              color: '#ff4500'
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Stack
                  direction='column'
                  spacing={1}
                >
                  <Typography
                    variant='body1'
                    fontWeight='700'
                  >
                    Datos de la tarjeta:
                  </Typography>

                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Stack
                      useFlexGap
                      flexWrap='wrap'
                      sx={{ width: '100%' }}
                      spacing={1}
                    >
                      <Stack
                        useFlexGap
                        flexWrap='wrap'
                        direction='row'
                        spacing={1}
                      >
                        <Tooltip title='Nombre del titular'>
                          <Chip 
                            icon={<Person />}
                            variant='outlined'
                            label={holderName}
                          />
                        </Tooltip>

                        <Tooltip title='Número de tarjeta'>
                          <Chip 
                            icon={<Numbers />}
                            variant='outlined'
                            label={cardNumber}
                          />
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Section>
      )}
    </Stack>
  )
}

const Section = ({ icon, title, children, spacing }) => {
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>
          <Stack spacing={spacing ?? 0}>{children}</Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
import React, { useContext, useState, useEffect } from 'react'
import { Container, Grid, Paper, Typography, Box, Tabs, Tab, IconButton, Stack, Button, Tooltip } from '@mui/material'
import ShoppingCartContext, { ShoppingCartProvider } from './ShoppingCartContext'
import { Add, ShoppingCartOutlined } from '@mui/icons-material'
import Copyright from '../../General/Copyright'
import Session from './Session'
import InfoCarousel from './InfoCarousel'
import CostumerForm from './CostumerForm'

function Carts() {
  /* useContext */
  const { carts, addCart, actualCart, setActualCart } = useContext(ShoppingCartContext)

  /* useState */
  const [showCostumerForm, setShowCostumerForm] = useState(false)
  const [creatingQuote, setCreatingQuote] = useState(false)

  /* items from sessionStorage */
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))

  /* useEffect */
  useEffect(() => { if(['Admin', 'PDV', 'Supervisor'].includes(permissions['role']) && carts.length === 0) setShowCostumerForm(true) }, [])

  return (
    <>
      <Container 
        maxWidth='lg'
        sx={{ mt: 4, mb: 4, textAlign: 'left' }}
      >
        <InfoCarousel></InfoCarousel>
        <Grid backgroundColor='default'
          container 
          spacing={1}
        >
          <Grid backgroundColor='default'
            item 
            xs={12}
            md={12}
          >
            <Paper
              variant='outlined'
              sx={{ p: 2, backgroundColor: 'default', minHeight:320 }}
            >
              {/*'Mi carrito'*/}
              <Stack backgroundColor= 'default'
              >
                <Typography
                  variant='h1'
                  sx={{ paddingY: 2, textAlign: 'left'}}
                >
                  Mis carritos
                </Typography>
                {carts.length === 0 ? (
                  <Stack
                    backgroundColor= 'default'
                    //spacing={100} //espacio de boton
                    //sx={{ p: 2 }}
                  >
                    <Stack
                      backgroundColor= 'default'
                      alignItems='center'
                      direction='column'
                      //spacing={1}
                      //marginTop={2}
                    >
                      <ShoppingCartOutlined sx={{ fontSize: 50 }} />
                      <Stack backgroundColor= 'default'
                      >
                        <Typography variant='subtitle1'>Sin Carritos</Typography>
                        <Typography variant='body1'>Para poder empezar, es necesario que inicies un carrito</Typography>
                      </Stack>
                    </Stack>
                    {/*Boton crear sesion*/}
                    <Stack
                      backgroundColor= 'default'
                      direction='row'
                      justifyContent='end'
                    >
                      <Button
                        variant='contained'
                        disabled={creatingQuote}
                        onClick={() => {
                          if(!['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){
                            setCreatingQuote(true)
                          }

                          if(['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){
                            setShowCostumerForm(true)
                            return
                          } 
                          
                          addCart(setCreatingQuote)
                        }}
                      >
                        Crear carrito
                      </Button>
                    </Stack>
                  </Stack>
                ) : (
                  <Paper variant='outlined'>
                    <Stack>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)' }}
                      >
                        <Tabs
                          value={actualCart}
                          onChange={(event, index) => {
                            setActualCart(index)
                            setActualCart(index)
                          }}
                          variant='scrollable'
                          visibleScrollbar={true}
                          scrollButtons={false}
                          allowScrollButtonsMobile
                        >
                          {carts.map((session, index) => (
                            <Tab label={`Carrito ${index + 1}`} />
                          ))}
                        </Tabs>

                        <Tooltip
                          title={carts.length >= 10 ? 'Solo puedes tener 10 sesiones' : 'Agregar Carrito'}
                          placement='left'
                          arrow
                        >
                          <Box>
                            <IconButton
                              disabled={carts.length >= 10 || creatingQuote}
                              onClick={() => {
                                if(!['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){
                                  setCreatingQuote(true)
                                }

                                if(['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){ 
                                  setShowCostumerForm(true)
                                  return
                                }

                                addCart(setCreatingQuote)
                              }}
                              sx={{
                                color: '#ff4500'
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Stack>
                      {carts.map((item, index) => {
                        if (index === actualCart) return <Session />
                      })}
                    </Stack>
                  </Paper>
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>

        <Paper
          sx={{
            p: 1,
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 50,
            width: 'auto'
          }}
        >
          <Copyright sx={{ pt: 4 }} />
        </Paper>
      </Container>

      <CostumerForm displayState={[showCostumerForm, setShowCostumerForm]} addCart={addCart} setCreatingQuote={setCreatingQuote} />  
  </>
  )
}

export default function MyShoppingCart() {
  return (
    <ShoppingCartProvider>
      <Carts />
    </ShoppingCartProvider>
  )
}

import { Alert, AlertTitle, Button,CircularProgress, Collapse, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { SelectionButton } from '../CreditMovements/CreditMovementsSharedComponents'
import { RefreshOutlined } from '@mui/icons-material'
import ShoppingCartContext from './ShoppingCartContext'

export default function PaymentMethods({ setActualStep, method, setMethod, balance, setBalance, amount }) {
  const { addObject, carts, actualCart } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))

  const [paying, setPaying] = useState(false)
  const [counter, setCounter] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasError, setHasError] = useState(false)
  const [timer, setTimer] = useState(null)
  const [paymentPDV, setPaymentPDV] = useState('');

  const getSubtitleByBalance = (balance) => {
    return balance 
      ? `$${Number(balance).toFixed(2)}` 
      : { null: 'No pudimos obtener tu saldo', undefined: 'Obteniendo tu saldo' }[balance];
  };
  async function getBalance() {
    try {
      setBalance(undefined)

      const request = await fetch(`${localhost}/api/v2/payment/credit`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (request.ok) {
        const response = await request.json()
        setBalance(response.balance)
        return
      }

      throw new Error(`${request.status} - ${request.statusText}`)
    } catch (error) {
      console.error('Get credit', error)
      setBalance(null)
    }
  }

  async function payWaybillWithCredit(payment_method = 'saldo') {
    // Declarando sub-funcion showingErrorMessage.
    const showingErrorMessage = message => {
      setErrorMessage(message)
      setHasError(true)
      setPaying(false)
      clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          setHasError(false)
        }, 10000)
      )
    }

    try {
      // Se activa el loading
      setPaying(true)

      // Se realiza el request.
      const request = await fetch(`${localhost}/api/v2/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        // body: JSON.stringify({
        //   amount: amount,
        //   method: paymentPDV,
        //   pendingPaysUUIDs: cart?.pendingPays?.length > 0 ? cart?.pendingPays?.map(pendingPay => pendingPay?.uuid) : [],
        //   costumer: cart.costumer ? cart.costumer : {},
        //   cart_uuid: cart.cart_uuid,
        //   cart_json: cart
        // })
        body: JSON.stringify({
          total: amount,
          payment_method: payment_method,
          ...cart
        })
      })

      // Comprobando si fue exitoso la petición
      if (request?.ok) {
        // Se Obtiene la respuesta
        const response = await request.json()

        if(response.payment && response.response){
          // Comprobando si ya se procedio el pago y ticket
          // if(response?.folio !== null){
            // Si ya se procedio el pago, entonces se agregara el folio al carrito y se procede en ir al siguiente paso
          addObject('total', response?.total)
          addObject('payment_method', response?.payment_method)
          addObject('payment', response?.payment)
          addObject('response', response?.response)
          setPaying(false)
          console.log('The request to payWaybill endpoint is finished.')
          setActualStep(2)
          // } else {
            // Imprimiendo mensaje en consola.
            // console.log(counter < 20 ? `The request to payWaybill endpoint is not finished yet, ${20 - (counter + 1)} attempts left do another request.` : 'There are not more attempts to do another request to postCart')

            // Imcrementando el counter
            // setCounter(counter + 1)

            // clearTimeout(timer)
            // setTimer(
            //   setTimeout(() => {
            //     const loading = true
            //     setPaying(loading)
            //   }, 5000)
            // )
            // }
        } else {
          showingErrorMessage(`Hubo algún inconveniente al momento de realizar el pago y generar los artículos, inténtelo más tarde, sí, el problema persiste, favor de contactar a soporte técnico.\n\t-${response?.quoteStatus !== null ? `Status actual del carrito: ${response?.quoteStatus}` : 'No se logro identificar/localizar el carrito.'}`)
        }
      } else {
        // console.log('Before print request status.')

        // console.log(request?.status)

        // console.log('After print request status.')

        // Se obtiene la respuesta.
        const response = await request.json()

        // Se revisa el status de la peticion
        switch (request.status) {
          case 402:
            throw new Error('No tienes saldo suficiente para realizar la compra')
          break

          case 400:
            throw new Error('Ocurrió un error al realizar el pago, inténtalo nuevamente más tarde, si el error persiste, contacta con soporte técnico')
          break
          
          // case 409:
          //   throw new Error(response.error)
          // break

          // case 504:
          //   // Imprimiendo mensaje en consola.
          //   console.log(counter < 20 ? `The request to payWaybill endpoint is not finished yet, ${20 - (counter + 1)} attempts left do another request.` : 'There are not more attempts to do another request to postCart')

          //   // Imcrementando el counter
          //   setCounter(counter + 1)
          // break

          default:
            throw new Error('Ocurrió un error al realizar el pago, contacta con soporte técnico')
          break
        }
      }
    } catch (error) {
      // Si hubo algun incovenient, se prepara todo para mostrarnos el error que se procovo
      console.error(error)
      showingErrorMessage(error.message)
    }
  }

  useEffect(() => {
    if (balance === undefined) getBalance()
  }, [])
  
  useEffect(() => {
    const nuevoPaymentPDV = transformarMethod(method, userPermissions.role);
    if (nuevoPaymentPDV !== null) {
        setPaymentPDV(nuevoPaymentPDV);
    }
  }, [method, userPermissions.role]);

  // En caso si el proceso de pago aun no se a completado, se realiza nuevamente la petición.
  useEffect(() => {
    // Se comprueba si cumple  dicha condición
    if(counter !== 0 && paying && counter < 20){
      // Se realiza un temporizador para que despues vuelva ejecutar el request que se hizo anteriormente.
      clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          console.log('Trying do another request to payWaybill endpoint.')
          payWaybillWithCredit({1: 'saldo', 5: 'efectivo', 6: 'terminal_bancaria', 7: 'transferencia', 8: 'pago_pendiente'}[method])
        }, 5000)
      )
    }

    // Se comprueba si se terminaron los intentos
    if(counter >= 20){
      // Sí se terminaron los intentos, entonces se desactiva el loading y regresa al primer paso
      setPaying(false)
      setActualStep(0)
    }
  }, [counter])

  return (
    <>
      {userPermissions['role'] !== 'SubCliente' && (
        <Stack
          spacing={2}
          useFlexGap
        >
          <Typography
            variant='body1'
            sx={{ fontSize: 20 }}
          >
            Total a pagar: ${Number(amount).toFixed(2)}
          </Typography>
        </Stack>
      )}

      <Collapse in={paying === false}>
        <Stack
          sx={{ marginTop: 2 }}
          spacing={2}
          useFlexGap
        >
          <Typography variant='body1'>Seleccione el método de pago para generar la guía</Typography>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Stack
                direction='row'
                alignItems='center'
                sx={{ height: '100%' }}
              >
                {!['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) &&
                  <SelectionButton
                    title='Saldo de la cuenta'
                    subtitle={userPermissions['role'] !== 'SubCliente' ? balance ? `$${Number(balance).toFixed(2)}` : { null: 'No pudimos obtener tu saldo', undefined: 'Obteniendo tu saldo' }[balance] : ''}
                    value={1}
                    selected={method}
                    onSelect={setMethod}
                  />
                }
                {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) && 
                <>
                  <SelectionButton
                    title='Efectivo'
                    subtitle={getSubtitleByBalance(balance)}
                    value={5}
                    selected={method}
                    onSelect={setMethod}
                  />
                  <SelectionButton
                    title='Terminal Bancaria'
                    subtitle={getSubtitleByBalance(balance)}
                    value={6}
                    selected={method}
                    onSelect={setMethod}
                  />
                  <SelectionButton
                    title='Transferencia'
                    subtitle={getSubtitleByBalance(balance)}
                    value={7}
                    selected={method}
                    onSelect={setMethod}
                  />
                </>}
                <Collapse
                  in={balance === null}
                  orientation='horizontal'
                >
                  <IconButton
                    sx={{ marginLeft: 1 }}
                    onClick={() => getBalance()}
                  >
                    <RefreshOutlined />
                  </IconButton>
                </Collapse>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectionButton
                title='Tarjeta de crédito o débito'
                subtitle='Próximamente'
                value={2}
                images={[require('../../../assets/paymentBrands/visa.webp'), require('../../../assets/paymentBrands/mastercard.webp')]}
                selected={method}
                //onSelect={setMethod}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectionButton
                title='Mercado Pago'
                subtitle='Próximamente'
                value={3}
                images={[require('../../../assets/paymentBrands/mercadopago.webp')]}
                selected={method}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectionButton
                title='PayPal'
                subtitle='Próximamente'
                value={4}
                images={[require('../../../assets/paymentBrands/paypal.webp')]}
                selected={method}
              />
            </Grid>

            {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) && cart.pendingPays?.length === 0 && ( 
              <Grid
                item
                xs={12}
                sm={6}
              >
                <SelectionButton 
                  title='Pago pendiente'
                  value={8}
                  images={[require('../../../assets/paymentBrands/pending.webp')]}
                  selected={method}
                  onSelect={setMethod}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Collapse>

      {['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? (
        <Alert
          severity='warning'
          sx={{ marginTop: 2 }}
        >
          <AlertTitle>Eres usuario Punto de Venta</AlertTitle>
          Tu rol es 'Punto de Venta', por lo que siempre se descontará el total a pagar tu saldo en cuenta, independientemente de qué método escojas
        </Alert>
      ) : (
        <></>
      )}

      <Collapse in={balance < amount && balance !== null}>
        <Alert
          severity='warning'
          sx={{ marginTop: 2 }}
        >
          <AlertTitle>Saldo insuficiente</AlertTitle>
          No cuentas con saldo suficiente para realizar esta compra, inténtalo con otro método de pago o recarga saldo a tu cuenta
        </Alert>
      </Collapse>

      <Collapse in={balance === null}>
        <Alert
          severity='error'
          sx={{ marginTop: 2 }}
        >
          <AlertTitle>Saldo desconocido</AlertTitle>
          No pudimos recuperar el saldo de tu cuenta, inténtalo más tarde
        </Alert>
      </Collapse>

      <Collapse in={hasError}>
        <Alert
          sx={{ marginTop: 2 }}
          severity='error'
        >
          {errorMessage}
        </Alert>
      </Collapse>

      <Stack
        direction='row'
        justifyContent='end'
        spacing={2}
        sx={{ marginTop: 2 }}
        useFlexGap
        flexWrap='wrap'
      >
        <Button
          variant='outlined'
          onClick={() => setActualStep(0)}
        >
          Atrás
        </Button>

        {method === 1 && (
          <Button
            variant='contained'
            disabled={balance < amount || balance === null || balance === undefined || paying === true}
            startIcon={paying === true ? <CircularProgress size={20} /> : null}
            onClick={() => payWaybillWithCredit()}
          >
            Pagar con saldo
          </Button>
        )}
        {paymentPDV === 'efectivo' && (
          <Button
            variant='contained'
            disabled={balance < amount || balance === null || balance === undefined || paying === true}
            startIcon={paying === true ? <CircularProgress size={20} /> : null}
            onClick={() => payWaybillWithCredit(paymentPDV)}
          >
            Pagar con Efectivo
          </Button>
        )}
        {paymentPDV === 'terminal_bancaria' && (
          <Button
            variant='contained'
            disabled={balance < amount || balance === null || balance === undefined || paying === true}
            startIcon={paying === true ? <CircularProgress size={20} /> : null}
            onClick={() => payWaybillWithCredit(paymentPDV)}
          >
            Pagar con terminal bancaria
          </Button>
        )}
        {paymentPDV === 'transferencia' && (
          <Button
            variant='contained'
            disabled={balance < amount || balance === null || balance === undefined || paying === true}
            startIcon={paying === true ? <CircularProgress size={20} /> : null}
            onClick={() => payWaybillWithCredit(paymentPDV)}
          >
            Pagar con transferencia
          </Button>
        )}
        {method === 8 && (
          <Button
            variant='contained'
            disabled={balance < amount || balance === null || balance === undefined || paying === true}
            startIcon={paying === true ? <CircularProgress size={20} /> : null}
            onClick={() => payWaybillWithCredit('pago_pendiente')}
          >
            Realizar pago pendiente
          </Button>
        )}
      </Stack>
    </>
  )
}

function transformarMethod(method, userRole) {
  if (!['Admin', 'PDV', 'Supervisor'].includes(userRole)) {
      return null;
  }

  switch (method) {
      case 5:
          return 'efectivo';
      case 6:
          return 'terminal_bancaria';
      case 7:
          return 'transferencia';
      default:
          return null;
  }
}

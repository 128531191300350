import { PriceCheckOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Checkbox, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import ShoppingCartContext from './ShoppingCartContext'

export default function GeneratingWaybills({ setActualStep, paymentMethod, removeCart }) {
  const { carts, actualCart, addObject } = useContext(ShoppingCartContext)
  const cart = carts[actualCart]
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')

  const [generatingWaybill, setGeneratingWaybill] = useState(false)
  const [counter, setCounter] = useState(0)
  const [timer, setTimer] = useState(null)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showExitDialog, setShowExitDialog] = useState(false)

  async function generateWaybill() {
    try {
      setGeneratingWaybill(true)
      setHasError(false)

      // if (cart?.payment === undefined || cart?.payment === null) throw new Error('No podemos validar tu pago, inténtalo más tarde, si el problema continua, contacta a soporte técnico')

      const request = await fetch(`${localhost}/api/v2/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(cart)
      })

      if (request?.ok) {
        const response = await request.json()

        if(response?.ticket !== null){
          addObject('response', response)
          setGeneratingWaybill(false)
          console.log('The request to postCart endpoint is finished.')
          setActualStep(3)
        } else {
          // Imprimiendo mensaje en consola.
          console.log(!counter >= 20 ? `The request to postCart endpoint is not finished yet, ${20 - (counter + 1)} attempts left to do another request.` : 'There are not more attempts to do another request to postCart')

          // Incrementando contandor
          setCounter(counter + 1)

          // clearTimeout(timer)
          // setTimer(
          //   setTimeout(() => {
          //     const loading = true
          //     setGeneratingWaybill(loading)
          //   }, 5000)
          // )
        }
      } else {
        switch (request.status) {
          case 402:
            throw new Error(`No podemos validar tu pago, inténtalo más tarde, si el problema continua, escríbenos al correo ${process.env.REACT_APP_SUPPORT_EMAIL}, o marca al teléfono ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}`)

          default:
            throw new Error('Ocurrió un error al generar la guía, inténtelo nuevamente más tarde')
        }
      }
    } catch (error) {
      console.error(error)
      setErrorMessage(error.message)
      setHasError(true)
      setGeneratingWaybill(false)
    }
  }

  useEffect(() => {
    if(!cart["response"]) generateWaybill();
  }, [])

  useEffect(() => {
    // Se comprueba si cumple con la condición.
    if(counter !== 0 && generatingWaybill && !cart["response"] && !counter >= 20) {
      // Se realiza temporizador para ejecutar de nuevo el request que se hizo anteriormente.
      clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          console.log('Trying do another request to postCart endpoint.')
          generateWaybill()
        }, 5000)
      )
    }

    // Se comprueba si se terminaron los intentos.
    if(counter >= 20){
      // Sí se terminaron, entonces se procede en desactivar el loading y regresarnos al primer paso.
      setGeneratingWaybill(false)
      setActualStep(0)
    }
  }, [counter])

  return (
    <>
      {generatingWaybill === true ? (
        <Stack
          spacing={2}
          sx={{ p: 2 }}
        >
          <Stack
            direction='row'
            spacing={2}
          >
            <CircularProgress size={40} />
            <Stack>
              <Typography variant='subtitle1'>Generando guía</Typography>
              <Typography variant='body1'>Espera un momento en lo que terminamos de generar tu guía</Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          spacing={2}
          sx={{ p: 2 }}
        >
          <Stack
            direction='row'
            spacing={2}
          >
            <PriceCheckOutlined sx={{ fontSize: 50 }} />
            <Stack>
              <Typography variant='subtitle1'>Pago realizado</Typography>
              <Typography variant='body1'>Ya pagaste por esta guía, puedes continuar para generarla</Typography>
            </Stack>
          </Stack>
        </Stack>
      )}

      <Collapse in={hasError === true && generatingWaybill === false}>
        <Alert
          sx={{ marginTop: 2 }}
          severity='error'
        >
          <AlertTitle>Ocurrió un problema</AlertTitle>
          <Stack spacing={1}>
            <Typography>
              No podemos validar tu pago, inténtalo más tarde, si el problema continua, escríbenos al correo
              <Link
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Problema con transacción, folio ${cart?.payment?.folio}&body=Hola, tengo un problema con una transacción con el folio ${cart?.payment?.folio}`}
                underline='none'
                rel='noreferrer'
              >
                {` ${process.env.REACT_APP_SUPPORT_EMAIL}`}
              </Link>
              , o al WhatsApp
              <Link
                href={`https://wa.me/+52${ process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER }?text=Hola, tengo un problema con una transacción con el folio ${cart?.payment?.folio}`}
                underline='none'
                target='_blank'
                rel='noreferrer'
              >
                {` ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}`}
              </Link>
            </Typography>
            <Typography>Folio de la transacción: {cart?.payment?.folio}</Typography>
          </Stack>
        </Alert>
      </Collapse>

      <Stack
        direction='row'
        spacing={1}
        justifyContent='end'
        sx={{ marginTop: 2 }}
      >
        {hasError === true && generatingWaybill === false && (
          <Button
            variant='outlined'
            color='error'
            onClick={() => setShowExitDialog(true)}
          >
            Salir
          </Button>
        )}
        <Button
          variant='contained'
          onClick={() => generateWaybill()}
          disabled={generatingWaybill === true}
          startIcon={generatingWaybill === true ? <CircularProgress size={20} /> : null}
        >
          Generar guía
        </Button>
      </Stack>

      <ExitDialog
        showExitDialog={showExitDialog}
        setShowExitDialog={setShowExitDialog}
        folio={cart?.payment?.folio}
        setActualStep={setActualStep}
        removeCart={removeCart}
      />
    </>
  )
}

const ExitDialog = ({ showExitDialog, setShowExitDialog, folio, setActualStep, removeCart }) => {
  const { removeObject } = useContext(ShoppingCartContext)
  const [accept, setAccept] = useState(false)

  return (
    <Dialog
      open={showExitDialog}
      onClose={() => setShowExitDialog(false)}
    >
      <DialogTitle>Antes de que salgas</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography>Si sales, toda la información referente a la compra se perderá y probablemente tendrás que volver a pagar por la guía, aun si esta ya fue pagada</Typography>

            <Typography>
              Te recomendamos que primero te pongas en contacto con soporte técnico antes de que salgas, puedes hacerlo mediante el correo
              <Link
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Problema con transacción, folio ${folio}&body=Hola, tengo un problema con una transacción con el folio ${folio}`}
                underline='none'
                rel='noreferrer'
              >
                {` ${process.env.REACT_APP_SUPPORT_EMAIL}`}
              </Link>
              , o al WhatsApp
              <Link
                href={`https://wa.me/+52${ process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER }?text=Hola, tengo un problema con una transacción con el folio ${folio}`}
                underline='none'
                target='_blank'
                rel='noreferrer'
              >
                {` ${process.env.REACT_APP_SALES_AND_SUPPORT_PHONE_NUMBER}`}
              </Link>
            </Typography>

            <Typography>Folio de la transacción: {folio}</Typography>
          </Stack>

          <Stack
            direction='row'
            alignItems='center'
          >
            <Checkbox
              onChange={event => setAccept(event.target.checked)}
              checked={accept}
            />
            <Typography>Comprendo y acepto las consecuencias que ocurrirán al salir</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction='row'
          spacing={1}
          justifyContent='end'
        >
          <Button
            variant='outlined'
            onClick={() => setShowExitDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='error'
            disabled={!accept}
            onClick={() => {
              setShowExitDialog(false)
              setAccept(false)
              setActualStep(1)
              removeObject('payment')
              removeCart()
            }}
          >
            Salir
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

import { RefreshOutlined, PlaceOutlined, NearMeOutlined, ReceiptLongOutlined } from '@mui/icons-material'
import { Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { Text } from 'recharts'

export default function DeliveryForm({ viewMerchandiseValue, setViewMerchandiseValue, viewOverweight, setViewOverweight, delivery, setDelivery, carriers, getCarriers, validatedDelivery, setValidatedDelivery }) {
  const regexInteger = /^\d*$/
  const regexNumber = /^\d{0,8}(\.\d{0,2})?$/
  const regexEmpty = /^\s*$/

  const [showMore, setShowMore] = useState(false)

  function sum() {
    const { sobrepeso, tarifaBase, primaSeguro, reexpedicion, irregular, cod, otros } = delivery
    const total = Number(sobrepeso) + Number(tarifaBase) + Number(primaSeguro) + Number(reexpedicion) + Number(irregular) + Number(cod) + Number(otros)

    return total.toFixed(2)
  }

  function showMoreHandler() {
    setShowMore(!showMore)
    if (showMore === false) setDelivery({ ...delivery, primaSeguro: 0, reexpedicion: 0, irregular: 0, cod: 0, otros: 0, amount: sum() })
  }

  useEffect(() => {
    let validated = true

    if (regexEmpty.test(delivery.numero)) validated = false
    if (!/^\d{5}$/.test(delivery.origenCP)) validated = false
    if (!/^\d{5}$/.test(delivery.destinoCP)) validated = false
    if (regexEmpty.test(delivery.origenPais)) validated = false
    if (regexEmpty.test(delivery.destinoPais)) validated = false
    if (regexEmpty.test(delivery.origenEmpresa)) validated = false
    if (regexEmpty.test(delivery.destinoEmpresa)) validated = false
    if (regexEmpty.test(delivery.origenPersona)) validated = false
    if (regexEmpty.test(delivery.destinoPersona)) validated = false
    if (Number(delivery.kg) <= 0) validated = false
    if (regexEmpty.test(delivery.ancho)) validated = false
    if (regexEmpty.test(delivery.alto)) validated = false
    if (regexEmpty.test(delivery.largo)) validated = false
    if (regexEmpty.test(delivery.carrier)) validated = false
    if (regexEmpty.test(delivery.service)) validated = false
    if (regexEmpty.test(delivery.sobrepeso)) validated = false
    if (regexEmpty.test(delivery.valorDeMercancia)) validated = false
    if (Number(delivery.tarifaBase) < 0) validated = false
    if (Number(delivery.amount) <= 0) validated = false

    if (showMore === true) {
      if (regexEmpty.test(delivery.primaSeguro)) validated = false
      if (regexEmpty.test(delivery.reexpedicion)) validated = false
      if (regexEmpty.test(delivery.irregular)) validated = false
      if (regexEmpty.test(delivery.cod)) validated = false
      if (regexEmpty.test(delivery.otros)) validated = false
    }

    setValidatedDelivery(validated)
  }, [delivery.numero, delivery.origenCP, delivery.destinoCP, delivery.origenPais, delivery.destinoPais, delivery.kg, delivery.ancho, delivery.alto, delivery.ancho, delivery.provider, delivery.service, delivery.valorDeMercancia, delivery.sobrepeso, delivery.tarifaBase, delivery.primaSeguro, delivery.reexpedicion, delivery.irregular, delivery.cod, delivery.otros])

  useEffect(() => {
    setDelivery({ ...delivery, costoDeEnvio: sum(), precio_final: sum() })
  }, [delivery.sobrepeso, delivery.tarifaBase, delivery.primaSeguro, delivery.reexpedicion, delivery.irregular, delivery.cod, delivery.otros])

  return (
    <Stack 
      useFlexGap
      padding={2}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <Paper
            variant='outlined'
            sx={{ border: 'solid 2px #FF4500' }}
          >
            <Stack
              direction='column'
              padding='0px 8px 6px 0px'
              spacing={1}
            >
              <Typography
                variant='h6'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ReceiptLongOutlined 
                  sx={{
                    fontSize: 25,
                    color: '#FF4500'
                  }}
                />
                Guía
              </Typography>

              <Grid
                container
                spacing={0.5}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Stack
                    direction='row'
                    alignItems='center'
                  >
                    <FormControl 
                      fullWidth
                    >
                      <InputLabel id='providerLabel'>Carrier</InputLabel>
                      <Select
                        disabled={carriers === undefined || carriers === null}
                        labelId='providerLabel'
                        value={delivery.carrier}
                        label='Carrier'
                        onChange={event => {
                          setDelivery({ ...delivery, carrier: event.target.value, service: undefined })
                          console.debug(delivery)
                        }}
                        fullWidth
                      >
                        {carriers?.map(service => (
                          <MenuItem value={service}>{service.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Collapse
                      in={carriers === null}
                      orientation='horizontal'
                    >
                      <IconButton
                        sx={{ marginLeft: 1 }}
                        onClick={() => getCarriers()}
                      >
                        <RefreshOutlined />
                      </IconButton>
                    </Collapse>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <FormControl 
                    fullWidth
                  >
                    <InputLabel id='serviceLabel'>Servicio</InputLabel>
                    <Select
                      labelId='serviceLabel'
                      value={delivery.service}
                      label='Servicio'
                      onChange={event => setDelivery({ ...delivery, service: event.target.value })}
                      fullWidth
                    >
                      {delivery?.carrier?.services?.map(service => (
                        <MenuItem value={service}>{service.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    label='Número de guía'
                    variant='outlined'
                    onChange={event => setDelivery({ ...delivery, numero: event.target.value })}
                    value={delivery.numero}
                    inputProps={{ maxlength: 100 }}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <TextField 
                    label='Valor de Mercancia'
                    variant='outlined'
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    onChange={event => {
                      const value = event.target.value
                      if (value === '' || regexNumber.test(value)){
                        setViewMerchandiseValue(value)
                        setDelivery({ ...delivery, valorDeMercancia: value === '' ? 0 : value })
                      } 
                    }}
                    value={viewMerchandiseValue}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Paper
            variant='outlined'
            sx={{ border: 'solid  2px #FF4500' }}
          >
            <Stack
              direction='column'
              justifyContent='center'
              padding='0px 6px 6px 6px'
              spacing={1}
            >
              <Typography
                variant='h6'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <PlaceOutlined 
                  sx={{ 
                    fontSize: 25,
                    color: '#FF4500' 
                  }} 
                />
                Origen
              </Typography>

              <TextField 
                label='Empresa de Origen'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, origenEmpresa: event.target.value.replace(/[^a-zA-Z ]/g, '') })}
                value={delivery.origenEmpresa}
                inputProps={{ maxlength: 70 }}
                fullWidth
              />

              <TextField 
                label='Nombre completo de origen'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, origenPersona: event.target.value.replace(/[^a-zA-Z ]/g, '') })}
                value={delivery.origenPersona}
                inputProps={{ maxlength: 80 }}
                fullWidth
              />

              <TextField
                label='Código postal de origen'
                variant='outlined'
                onChange={event => {
                  const value = event.target.value
                  if (regexInteger.test(value)) setDelivery({ ...delivery, origenCP: value })
                }}
                value={delivery.origenCP}
                inputProps={{ maxlength: 5 }}
                fullWidth
              />

              <TextField 
                label='País de Origen'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, origenPais: event.target.value.replace(/[^a-zA-Z ]/g, '') })}
                value={delivery.origenPais}
                inputProps={{ maxlength: 40 }}
                fullWidth
              />
            </Stack>
          </Paper>
        </Grid>
        
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Paper
            variant='outlined'
            sx={{ border: 'solid 2px #FF4500' }}
          >
            <Stack
              direction='column'
              justifyContent='center'
              padding='0px 6px 6px 6px'
              spacing={1}
            >
              <Typography
                variant='h6'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <NearMeOutlined 
                  sx={{ 
                    fontSize: 25,
                    color: '#FF4500' 
                  }} 
                />
                Destino
              </Typography>

              <TextField 
                label='Empresa de destino'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, destinoEmpresa: event.target.value.replace(/[^a-zA-Z ]/g, '') })}
                value={delivery.destinoEmpresa}
                inputProps={{ maxlength: 70 }}
                fullWidth
              />

              <TextField 
                label='Nombre completo de destino'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, destinoPersona: event.target.value.replace(/[^a-zA-Z ]]/g, '') })}
                value={delivery.destinoPersona}
                inputProps={{ maxlength: 80 }}
                fullWidth
              />

              <TextField
                label='Código postal de destino'
                variant='outlined'
                onChange={event => {
                  const value = event.target.value
                  if (regexInteger.test(value)) setDelivery({ ...delivery, destinoCP: value })
                }}
                value={delivery.destinoCP}
                inputProps={{ maxlength: 5 }}
                fullWidth
              />

              <TextField 
                label='País de Destino'
                variant='outlined'
                onChange={event => setDelivery({ ...delivery, destinoPais: event.target.value.replace(/[^a-zA-Z ]/g, '') })}
                value={delivery.destinoPais}
                inputProps={{ maxlength: 40 }}
                fullWidth
              />
            </Stack>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          display='flex'
          alignItems='center'
          justifyContent='center'
          gap={1}
        >
          <FormControl
            fullWidth
          >
            <InputLabel id='esSobreLabel'>Tipo de envio</InputLabel>
            <Select
              labelId='esSobreLabel'
              label='Tipo de envio'
              value={delivery.esSobre}
              onChange={event => setDelivery({...delivery, esSobre: Number(event.target.value)})}
              fullWidth
            >
              <MenuItem value='1'>Sobre</MenuItem>
              <MenuItem value='0'>Paquete</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label='Peso'
            variant='outlined'
            InputProps={{
              endAdornment: <InputAdornment position='end'>kg</InputAdornment>
            }}
            onChange={event => {
              const value = event.target.value
              if (regexNumber.test(value)) setDelivery({ ...delivery, kg: value })
            }}
            value={delivery.kg}
            fullWidth
          />
        </Grid>
        
        {!delivery.esSobre && (
            <Grid
              item
              xs={12}
              display='flex'
              alignItems='center'
              justifyContent='center'
              gap={1}
            >
              <TextField
                label='Ancho'
                variant='outlined'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>
                }}
                onChange={event => {
                  const value = event.target.value
                  if (regexNumber.test(value)) setDelivery({ ...delivery, ancho: value })
                }}
                value={delivery.ancho}
                fullWidth
              />

              <TextField
                label='Alto'
                variant='outlined'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>
                }}
                onChange={event => {
                  const value = event.target.value
                  if (regexNumber.test(value)) setDelivery({ ...delivery, alto: value })
                }}
                value={delivery.alto}
                fullWidth
              />

              <TextField
                label='Largo'
                variant='outlined'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>
                }}
                onChange={event => {
                  const value = event.target.value
                  if (regexNumber.test(value)) setDelivery({ ...delivery, largo: value })
                }}
                value={delivery.largo}
                fullWidth
              />
            </Grid>
          )
        }

        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            label='Tarifa base'
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>
            }}
            onChange={event => {
              const value = event.target.value
              if (regexNumber.test(value)) setDelivery({ ...delivery, tarifaBase: value })
            }}
            value={delivery.tarifaBase}
            fullWidth
          />
        </Grid>
        
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            label='Sobrepeso'
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>
            }}
            onChange={event => {
              const value = event.target.value
              if (value === '' || regexNumber.test(value)) {
                setViewOverweight(value) 
                setDelivery({ ...delivery, sobrepeso: value === '' ? 0 : value }) 
              }
            }}
            value={viewOverweight}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
        >
          <Stack spacing={1}>
            <Typography variant='caption'>Costo de envío</Typography>
            <Typography variant='body1'>${delivery.costoDeEnvio}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Link
        onClick={() => showMoreHandler()}
        sx={{ py: 2, cursor: 'pointer' }}
      >
        <Typography variant='body1'>Mostrar más campos</Typography>
      </Link>

      <Collapse in={showMore}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              label='Prima de seguro'
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              onChange={event => {
                const value = event.target.value
                if (regexNumber.test(value)) setDelivery({ ...delivery, primaSeguro: value })
              }}
              value={delivery.primaSeguro}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              label='Reexpedición'
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              onChange={event => {
                const value = event.target.value
                if (regexNumber.test(value)) setDelivery({ ...delivery, reexpedicion: value })
              }}
              value={delivery.reexpedicion}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              label='Irregular'
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              onChange={event => {
                const value = event.target.value
                if (regexNumber.test(value)) setDelivery({ ...delivery, irregular: value })
              }}
              value={delivery.irregular}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              label='COD'
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              onChange={event => {
                const value = event.target.value
                if (regexNumber.test(value)) setDelivery({ ...delivery, cod: value })
              }}
              value={delivery.cod}
              fullWidth
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <TextField
              label='Otros'
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>
              }}
              onChange={event => {
                const value = event.target.value
                if (regexNumber.test(value)) setDelivery({ ...delivery, otros: value })
              }}
              value={delivery.otros}
              fullWidth
            />
          </Grid>
        </Grid>
      </Collapse>
    </Stack>
  )
}

/* Importaciones de React */
import React, { useState, useEffect, useCallback } from "react"

/* Importaciones de MUI */
import { Stack, Typography, TextField, InputAdornment, Button } from "@mui/material"
import { Person, Numbers } from "@mui/icons-material"

/* Importaciones del modulo de Shared */
import { ModalView } from "../../Shared"

export default function CostumerForm({ displayState, addCart, setCreatingQuote }){
    /* Estados */
    const [show, setShow] = displayState
    const [costumerName, setCostumerName] = useState('')
    const [costumerDNI, setCostumerDNI] = useState('')

    /* Componente */
    return(
        <ModalView
            displayState={displayState}
            width={'32%'}
            maxHeight={'85%'}
        >
            <Stack
                direction='column'
                spacing={2}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'center'
                    }}
                >
                    Complete los campos para abrir un nuevo carrito
                </Typography>

                <TextField 
                    label={'Nombre completo del cliente'}
                    placeholder={'Alejandro Gomez Torres'}
                    value={costumerName}
                    onChange={event => setCostumerName(event.target.value.replace(/[^a-zA-Z ]/g, ''))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Person color="primary" /></InputAdornment>
                    }}
                    fullWidth
                    focused
                    required
                />

                <TextField 
                    label={'Numero de identificación del cliente(Opcional)'}
                    placeholder={'###############'}
                    value={costumerDNI}
                    onChange={event => setCostumerDNI(event.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Numbers color="primary" /></InputAdornment>
                    }}
                    fullWidth
                    focused
                />
            </Stack>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='right'
            >
                <Button 
                    onClick={() => {
                        if(costumerName !== ''){
                            setCreatingQuote(true)
                            addCart(setCreatingQuote, { name: costumerName, DNI: costumerDNI })
                            setShow(false)
                            return
                        }

                        alert('Estimado usuario, es importante que minimo ingrese el nombre del cliente.')
                    }}
                    sx={{ fontSize: '12.4px' }}
                >
                    Agregar cliente y crear carrito
                </Button>

                <Button
                    onClick={() => {
                        setCostumerName('')
                        setCostumerDNI('')
                        setShow(false)
                    }}
                    sx={{ fontSize: '12.4px' }}
                >
                    Cerrar
                </Button>
            </Stack>
        </ModalView>
    )
}
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Checkbox, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export default function ProductForm({ product, setProduct, waybills, validatedProduct, setValidatedProduct }) {
  const { name, description, quantity, amount, total, waybill } = product
  const regexInteger = /^\d{0,3}$/
  const regexNumber = /^\d{0,8}(\.\d{0,2})?$/
  const regexEmpty = /^\s*$/

  const [isLinked, setIsLinked] = useState(false)

  function isLinkedHandler(event) {
    const value = event.target.checked
    setIsLinked(value)
    if (value === false) setProduct({ ...product, waybill: null })
  }

  useEffect(() => {
    setProduct({ ...product, total: (Number(amount) * Number(quantity)).toFixed(2) })
  }, [amount, quantity])

  useEffect(() => {
    let validated = true

    if (regexEmpty.test(name)) validated = false
    if (regexEmpty.test(quantity) || Number(quantity) === 0) validated = false
    if (regexEmpty.test(amount) || Number(amount) === 0) validated = false

    if (isLinked === true) {
      if (waybill === null) validated = false
    }

    setValidatedProduct(validated)
  }, [name, quantity, amount, waybill, isLinked])

  return (
    <Stack useFlexGap>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <TextField
            label='Producto'
            variant='outlined'
            onChange={event => setProduct({ ...product, name: event.target.value })}
            value={name}
            inputProps={{ maxlength: 100 }}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            label='Cantidad'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton
                    onClick={() => {
                      if (quantity > 0) setProduct({ ...product, quantity: Number(quantity) - 1 })
                    }}
                  >
                    <RemoveOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      if (quantity < 999) setProduct({ ...product, quantity: Number(quantity) + 1 })
                    }}
                  >
                    <AddOutlined />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={event => {
              const value = event.target.value
              if (regexInteger.test(value)) setProduct({ ...product, quantity: value })
            }}
            value={quantity}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            label='Precio unitario'
            variant='outlined'
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>
            }}
            onChange={event => {
              const value = event.target.value
              if (regexNumber.test(value)) setProduct({ ...product, amount: value })
            }}
            value={amount}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
        >
          {/* <Stack spacing={1}>
            <Typography variant='caption'>Total</Typography>
            <Typography variant='body1'>${total}</Typography>
          </Stack> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Stack
            direction='row'
            alignItems='center'
          >
            <Checkbox
              value={isLinked}
              onChange={event => isLinkedHandler(event)}
            />
            <Typography variant='body1'>Enlazar producto con cotización o entrega</Typography>
          </Stack>
          <Collapse in={isLinked === true}>
            {waybills.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id='waybill-selector'>Guía enlazada</InputLabel>
                <Select
                  labelId='waybill-selector'
                  id='waybill'
                  label='Guía enlazada'
                  value={product.waybill}
                  onChange={event => setProduct({ ...product, waybill: event.target.value })}
                >
                  {waybills.map(waybill => (
                    <MenuItem value={waybill}>{waybill.name ? waybill.name : waybill}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography>No hay cotizaciones o entregas en este carrito</Typography>
            )}
          </Collapse>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <TextField
            label='Información adicional'
            multiline
            rows={4}
            variant='outlined'
            onChange={event => setProduct({ ...product, description: event.target.value })}
            inputProps={{ maxlength: 255 }}
            value={description}
            fullWidth
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

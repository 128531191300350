import { createContext, useEffect, useState } from 'react'

const ShoppingCartContext = createContext(1)

const ShoppingCartProvider = ({ children }) => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const permissions = JSON.parse(sessionStorage.getItem('permissions'))
  const [sessions] = useState(JSON.parse(localStorage.getItem('sessions')))
  const [carts, setCarts] = useState([])
  const [actualCart, setActualCart] = useState(0)

  useEffect(() => {
    if(sessions !== null){
      const carts = sessions[user.id].cart
      if (!carts) {
        if(!['Admin', 'PDV', 'Supervisor'].includes(permissions['role'])){
          addCart()
        }
      } else {
        setCarts(carts)
      }
    }
  }, [sessions])

  const createQuote = async (setCreatingQuote, costumer) => {
    try {
      // Se realiza la siguiente petición junto con la información que se va a mandar.
      const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/cart/quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        },
        body: JSON.stringify(costumer)
      })

      // Comprobando si todo fue en orden.
      if(request.ok){
        // Se obtiene la respuesta.
        const response = await request.json()

        // Se devuelve el cart_uuid
        return response['cart_uuid']
      }

      // Si sucedio algo con la petición, se dirigira al catch.
      throw new Error('')
    } catch (error) {
      // Se habilita los botones de crear sesiones
      if(typeof setCreatingQuote === 'function') setCreatingQuote(false)

      // Se devuelve un null
      return null
    } finally {
      if(typeof setCreatingQuote === 'function') setCreatingQuote(false)
    }
  }

  const deleteQuote = async (setDeletingQuote, cart_uuid) => {
    try {
      const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/cart/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        },
        body: JSON.stringify({
          cart_uuid: cart_uuid
        })
      })

      if(request.ok){
        const response = await request.json()
        return true
      }

      throw new Error('')
    } catch (error) {
      setDeletingQuote(false)
      return false
    } finally {
      setDeletingQuote(false)
    }
  }

  function updateSession(removingACart = null) {
    sessions[user.id].cart = removingACart !== null ? removingACart : carts
    localStorage.setItem('sessions', JSON.stringify(sessions))
  }

  async function addCart(setCreatingQuote, costumer = {}) {
    // Se comprueba si el usuario si ya tiene 5 sesiones o carritos ya creados.
    if (carts.length >= 10) return

    // Se carga la informacion del cliente
    costumer = costumer['name'] ? { costumer: { ...costumer }} : {}

    // Se realiza la siguiente petición con el fin de crear un quote y obtener el cart_uuid
    const cart_uuid = await createQuote(setCreatingQuote, costumer)

    // Se comprueba si creo el quote
    if(cart_uuid === null) return

    // Se agrega una nueva sesion o carrito
    carts.push({cart_uuid: cart_uuid, waybills: [], products: [], deliveries: [], pendingPays: [], ...costumer })

    // Posicionando en la nueva sesion
    setActualCart(carts.length - 1)

    // Se actualiza la sesion
    updateSession()

    // Se actualiza el estado de carts
    setCarts([...carts])
  }

  async function removeCart(setDeletingQuote) {
    if(typeof setDeletingQuote === 'function'){
      // Cargando la sesión que se va eliminar.
      const cart = carts[actualCart]

      // Se realiza la siguiente petición con el fin de eliminar un quote.
      const isDelete = await deleteQuote(setDeletingQuote, cart?.cart_uuid)

      // Comprobando sí se elimino la sesión.
      if(!isDelete) return alert('Algo ocurrio al momento de eliminar la sesión. Intentelo más tarde y deje para despues en eliminar esta sesión.')
    }

    // Se filtra el array y se excluye la sesión que se va a eliminar.
    const cartsFiltered = carts.filter((cart, i) => i !== actualCart)

    // Se actualiza la posición para que nos muestre la sesión correspondiente.
    setActualCart(actualCart === 0 ? 0 : actualCart - 1)

    // Se actualiza la sesión.
    updateSession(cartsFiltered)

    // Se actualiza el estado de carts.
    setCarts([...cartsFiltered])
  }

  function addItem(type, data) {
    if (carts[actualCart][type] === undefined) carts[actualCart][type] = []
    carts[actualCart][type].push({ ...data, stamp: new Date().valueOf(), type: type })
    updateSession()
    setCarts([...carts])
  }

  function removeItem(type, index) {
    carts[actualCart][type] = carts[actualCart][type].filter((item, i) => i !== index)
    updateSession()
    setCarts([...carts])
  }

  function addObject(type, data) {
    carts[actualCart][type] = data
    updateSession()
    setCarts([...carts])
  }

  function removeObject(type) {
    carts[actualCart][type] = undefined
    updateSession()
    setCarts([...carts])
  }

  function reset() {
    sessions[user.id] = { cart: [] }
    localStorage.setItem('sessions', JSON.stringify(sessions))
  }

  function print() {
    console.debug(carts)
  }

  const params = {
    carts,
    setCarts,
    addCart,
    removeCart,
    actualCart,
    setActualCart,
    addItem,
    removeItem,
    addObject,
    removeObject,
    print,
    reset
  }

  return <ShoppingCartContext.Provider value={params}>{children}</ShoppingCartContext.Provider>
}

export { ShoppingCartProvider }
export default ShoppingCartContext

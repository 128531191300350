import React , { useState , useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {  Typography, TableCell, TableRow, IconButton, Stack } from '@mui/material';
import { AddShoppingCartOutlined, AirOutlined, Replay } from '@mui/icons-material';

import { LuServerOff } from 'react-icons/lu'

import './shippingStyles.css';
import DownloadButton from '../../../Shared/DownloadButton';
import { MainContainer, SkeletonLoad, Table, Message } from '../../../Shared'

export default function WaybillsTable(props){
    /* Estados */
    const [ loading, setLoading ] = useState(true)
    const [ messageTitle, setMessageTitle ] = useState('')
    const [ messageContent, setMessageContent ] = useState('')
    const [ waybills , setWaybills ] = useState(null)
    const navigate = useNavigate();

    /* Objeto user de Session Storage */
    //const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    /*const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };*/
    
    /* Objeto de estilos para la tabla de guias */
    const waybillsTableCSS = {
        typography : { 
            fontWeight : '600',
            display : 'flex',
            marginLeft : '5px'
        },
        subTypography : {
            marginLeft : '5px',
            color: '#ff4500'
        },
        downloadButton : {
            fontSize : "10px",
            color : "#ffffff",
            backgroundColor : "#0227e2",
            border : "2px solid #006ff4",
            "&:hover" : {
                backgroundColor : "#011ca4",
                border : "2px solid #014ca6",
            }
        },
        cancelButton : {
            fontSize : "10px",
            color : "#ffffff",
            backgroundColor : "#e91104",
            border : "2px solid #fa4f44",
            "&:hover" : {
                backgroundColor : "#b00a00",
                border : "2px solid #bf3d35",
            },
            marginTop : "15px",
        },
        textData : {
            color : '#ff4500'
        }
    };
    /* Generando columnas */
    // const tableCellsHead = columns.map( ( item , index ) => (
    //     <TableCell>
    //         { item.column }
    //     </TableCell>
    // ));
    
    /* Generando los renglones de la tabla de guias */
    const WaybillRow = useCallback(({ item }) => {
        const { destinoPersona, destinoMunicipio, destinoEstado, destinoCP, origenPersona, origenMunicipio, origenEstado, origenCP, created_at, numero, name, kg, ticketPagoOrigen } = item

        const destinationData = [
            {
                label : 'Nombre: ',
                data : destinoPersona
            },
            {
                label : 'Municipio: ',
                data : destinoMunicipio
            },
            {
                label : 'Estado: ',
                data : destinoEstado
            },
            {
                label : 'C.P: ',
                data : destinoCP
            },
        ];

        const originData = [
            {
                label : 'Nombre: ',
                data : origenPersona
            },
            {
                label : 'Municipio: ',
                data : origenMunicipio
            },
            {
                label : 'Estado: ',
                data : origenEstado
            },
            {
                label : 'C.P: ',
                data : origenCP
            },
        ];

        const date = new Date( created_at );
        const month = ( date.getMonth() < 9 ) ? '0' + String( date.getMonth() + 1 ) : date.getMonth() + 1 ;
        const monthDay = ( date.getDate() < 10 ) ? '0' + String( date.getDate() ) : date.getDate();

        return (
            <TableRow>
                <TableCell sx = { waybillsTableCSS.textData }>
                    { monthDay }/{ month }/{ date.getFullYear() }
                </TableCell>
                <TableCell sx = { waybillsTableCSS.textData }>
                    { numero }
                </TableCell>
                <TableCell>
                    {
                        originData.map( ( data ) => (
                            <div>
                                <Typography variant = "body2" component = "div" sx = { waybillsTableCSS.typography }>
                                    { data.label }
                                </Typography>

                                <Typography variant = "body2" sx = { waybillsTableCSS.subTypography }>
                                    { data.data }
                                </Typography>
                            </div> 
                        ))
                    }
                </TableCell>
                <TableCell>
                    {
                        destinationData.map( ( data ) => (
                            <div>
                                <Typography variant = "body2" component = "div" sx = { waybillsTableCSS.typography }>
                                    { data.label }
                                </Typography>

                                <Typography variant = "body2" sx = { waybillsTableCSS.subTypography }>
                                    { data.data }
                                </Typography>
                            </div>
                        ) )
                    }
                </TableCell>
                <TableCell 
                    align = "center"
                    sx = { waybillsTableCSS.textData }
                >
                    { name }
                </TableCell>
                <TableCell 
                    align = "center"
                    sx = { waybillsTableCSS.textData }
                >
                    { kg }
                </TableCell>
                <TableCell align="center">
                    <DownloadButton type="waybill" identifier={numero} />
                </TableCell>
                <TableCell align="center">
                    <DownloadButton type="ticket" identifier={ticketPagoOrigen} />
                </TableCell>
            </TableRow>
        ) 
    }, []);

    /*const InfoMessage = (props) => {
        let messageTitle = ''
        let messageContent = ''
        let messageIcon = null

        if(requestError.isThereError){
            switch(requestError.status){
                case 500: 
                    messageTitle = 'Hubo un problema con el servidor'
                    messageContent = 'Ocurrio un problema al momento de traer las guías, intentelo más tarde.'
                    messageIcon = <LuServerOff fontSize={50} />
                break;
                default: 
                    messageTitle = 'Hubo un problema con la conexión'
                    messageContent = 'Revise sí se encuentra conectado a una señal de internet y intentelo de nuevo.'
                    messageIcon = <WifiOff fontSize={50} />
                break;
            }
        }

        if(!requestError.isThereError){
            messageTitle = 'Sin guías'
            messageContent = 'Para poder visualizar las guías, realiza una cotización en el modulo de Mi Carrito.'
            messageIcon = <AirOutlined fontSize={50} />
        }

        return(
            <Message 
                title={messageTitle}
                message={messageContent}
                icon={messageIcon}
                callback={requestError.isThereError ? getWaybills() : () => navigate('/MyShoppingCart')}
                buttonMessage={
                    requestError.isThereError ? 
                        <Stack
                            direction='row'
                            alignItems='center'
                            gap={2}
                        >
                            <Replay color='#ffffff' />

                            <Typography
                                fontSize={15}
                            >
                                Volver intentar
                            </Typography>
                        </Stack>
                    : 
                        <Stack
                            direction='row'
                            alignItems='center'
                            gap={2}
                        >
                            <AddShoppingCartOutlined color='#ffffff' />

                            <Typography
                                fontSize={15}
                            >
                                Crear una cotización
                            </Typography>
                        </Stack>
                }
            />
        )
    }*/

    /* Abriendo la ventana de confirmacion */
    /*const handleClickOpen = () => {
        setOpen( true );
    }*/

    /* Cerrando la ventana de confirmacion */
    /*const handleClose = () => {
        setOpen( false );
    }*/

    /* Reseteando el objeto de origen y destino */
    // function clearDataObject(){
    //     objectJSON.data.origin.origenEmpresa = "";
    //     objectJSON.data.origin.origenPersona.nombre = "";
    //     objectJSON.data.origin.origenPersona.apellidoPaterno = "";
    //     objectJSON.data.origin.origenPersona.apellidoMaterno = "";
    //     objectJSON.data.origin.id_dom_origen = null;
    //     objectJSON.data.origin.origenCalle = "";
    //     objectJSON.data.origin.origenNumExt = "";
    //     objectJSON.data.origin.origenNumInt = "";
    //     objectJSON.data.origin.origenColonia = "";
    //     objectJSON.data.origin.origenMunicipio = "";
    //     objectJSON.data.origin.origenEstado = "";
    //     objectJSON.data.origin.origenPais = "";
    //     objectJSON.data.origin.origenRef = "";
    //     objectJSON.data.origin.origenCP = "";
    //     objectJSON.data.origin.origenTelefono1 = "";
    //     objectJSON.data.origin.origenTelefono2 = "";

    //     objectJSON.data.destination.destinoEmpresa = "";
    //     objectJSON.data.destination.destinoPersona.nombre = "";
    //     objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
    //     objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
    //     objectJSON.data.destination.id_dom_destino = null;
    //     objectJSON.data.destination.destinoCalle = "";
    //     objectJSON.data.destination.destinoNumExt = "";
    //     objectJSON.data.destination.destinoNumInt = "";
    //     objectJSON.data.destination.destinoColonia = "";
    //     objectJSON.data.destination.destinoMunicipio = "";
    //     objectJSON.data.destination.destinoEstado = "";
    //     objectJSON.data.destination.destinoPais = "";
    //     objectJSON.data.destination.destinoRef = "";
    //     objectJSON.data.destination.destinoCP = "";
    //     objectJSON.data.destination.destinoTelefono1 = "";
    //     objectJSON.data.destination.destinoTelefono2 = "";

    //     objectJSON.data.kilosServiceAndNumber.numero = "";
    //     objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = 0;
    //     objectJSON.data.kilosServiceAndNumber.kg = 1.0;
    //     objectJSON.data.kilosServiceAndNumber.alto = 1;
    //     objectJSON.data.kilosServiceAndNumber.ancho = 1;
    //     objectJSON.data.kilosServiceAndNumber.largo = 1;

    //     objectJSON.data.whoIsPaying.montoACobrar = 0;
    //     objectJSON.data.whoIsPaying.costoDeEnvio = 1.0;
    //     objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;
    //     objectJSON.data.whoIsPaying.origenDestino = "Origen";

    //     objectJSON.data.isQuote = false;
    // }

    /* Obteniendo las guias creadas por el usuario */
    const getWaybills = async () => {
        try {
            setLoading(true)

            const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/waybills`, {
                method : 'GET',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Cache-Control': 'no-cache'
                }
            })
            
            if(request?.ok) {
                const response = await request.json()
                setWaybills( response?.data )
                if(response?.data.length === 0){
                    setMessageTitle('Sin guías')
                    setMessageContent('Para poder visualizar las guías, realiza una cotización en el modulo de Mi Carrito.')
                }
                return
            }

            throw new Error('Hubo un problema con el servidor-Ocurrio un problema al momento de traer las guías, intentelo más tarde.')
        } catch (error) {
            const requestErrorMessage = error.message.split('-')
            setMessageTitle(requestErrorMessage[0])
            setMessageContent(requestErrorMessage[1])
            setWaybills(null)
        } finally {
            setLoading(false)
        }
    }

    useEffect( () => getWaybills() , [] );
    // useEffect( () => clearDataObject() , [] );
    // useEffect( () => alert('La respuesta es: ' + JSON.stringify(arrayWaybills)) , [ arrayWaybills ]  );

    /* Retorno de la vista del modulo */
    return(
        <MainContainer>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography
                    variant='h1'
                    sx={{ paddingY: 2 }}
                >
                    Tus guías
                </Typography>

                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-evenly'
                >
                    {/* <IconButton
                        color='success'
                        title='Crear guía masiva'
                        onClick={() => navigate('/massiveWaybills')}
                    >
                        <LibraryAddOutlined />
                    </IconButton> */}

                    <IconButton
                        color='primary'
                        title='Crear una cotización'
                        onClick={() => navigate('/MyShoppingCart')}
                    >
                        <AddShoppingCartOutlined />
                    </IconButton>
                </Stack>
            </Stack>

            {loading && (
                <SkeletonLoad 
                    types={['rectangular']}
                    numberOfItems={5}
                    randomHeight
                    heightRange={[50, 120]}
                />
            )}

            {!loading && (waybills === null || waybills?.length === 0) && (
                <Message 
                    title={messageTitle}
                    message={messageContent}
                    icon={waybills === null ? <LuServerOff fontSize={50} /> : <AirOutlined sx={{ fontSize: 50 }} />}
                    callback={waybills === null ? getWaybills : () => navigate('/MyShoppingCart')}
                    buttonMessage={
                        waybills === null ? 
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='center'
                                gap={2}
                                color='#ffffff'
                                fontWeight='600'
                                border='2px solid #ff4500'
                                borderRadius={3}
                                bgcolor='#ff4500'
                                paddingY={0.3}
                                paddingX={1}
                                sx={{
                                    '&:hover': {
                                        color: '#dddddd',
                                        border: '2px solid #d53a00',
                                        bgcolor: '#d53a00'
                                    }
                                }}
                            >
                                <Replay />

                                <Typography
                                    fontSize={15}
                                >
                                    Volver intentar
                                </Typography>
                            </Stack>
                        : 
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='center'
                                gap={2}
                                color='#ffffff'
                                fontWeight='600'
                                border='2px solid #ff4500'
                                borderRadius={3}
                                bgcolor='#ff4500'
                                paddingY={0.3}
                                paddingX={1}
                                sx={{
                                    '&:hover': {
                                        color: '#dddddd',
                                        border: '2px solid #d53a00',
                                        bgcolor: '#d53a00'
                                    }
                                }}
                            >
                                <AddShoppingCartOutlined />

                                <Typography
                                    fontSize={15}
                                >
                                    Crear una cotización
                                </Typography>
                            </Stack>
                    }
                />
            )}

            {!loading && waybills?.length > 0 && (
                <Table 
                    headers={['Fecha', 'Numero de Guia', 'Origen', 'Destino', 'Servicio', 'Kilos Amparados', 'Descargar guia', 'Descargar ticket']}
                    data={waybills}
                    pagination
                    RowComponent={WaybillRow}
                />
            )}
        </MainContainer>
    );
}

/*

<>
            <div className = "container container--waybillsTable ">
                <div className = "containerGrid">
                    <Grid container spacing = { 2 }>
                        <Grid item xs = { 8 }
                        >
                            <Typography
                                variant = "body2"
                                sx = {{
                                    color : "#ff4500",
                                    fontWeight : "bold",
                                    fontSize : "23px",
                                    marginLeft : "270px"
                                }}
                            >
                                Tus Guias
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs = { 4 }
                        >
                            <div align = "right">
                                {/* <ButtonLink 
                                    url = "/originOrDestination"
                                    clases = ""
                                    texto = "+Agregar" 
                                /> */

                                /*<IconButton 
                                    onClick = { () => goTo( "/massiveWaybills" ) }
                                    title = "Crear guia masiva"
                                    color = "success"
                                >
                                    <LibraryAdd/>
                                </IconButton>

                                <IconButton 
                                    onClick = { () => goTo( "/originOrDestination" ) }
                                    title = "Crear guia"
                                    color = "primary"
                                >
                                    <NoteAdd/>
                                </IconButton>
                            </div>
                        </Grid>

                        <Grid item xs = { 12 }>*/

                            /* Tabla de guias }
                            <TableContainer sx = {{ maxHeight: 440 }} component = {Paper}>
                                <Table stickyHeader aria-label="sticky table">

                                    <TableHead>
                                        <TableRow>
                                            { tableCellsHead }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        { waybillsTableRows }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>

                    </Grid>
                </div>
            </div>

            <ConfirmationDialog 
                open = { open }
                handleClose = { handleClose }
                waybillNumber = { waybillNumber }
            />
        </>
*/

/*return(
    <Container maxWidth="lg">
        <Paper elevation={3} sx={ { marginY: 5, paddingX: 5, paddingY: 5} }>

            
            <Container sx={ {marginBottom: 2, textAlign: "left"} }>
                <Grid container spacing={2}>

                    
                    <Grid item xs={12} md={9}>
                        <Typography variant='h5'>
                            Tus guias
                        </Typography>
                    </Grid>

                    
                    <Grid item xs={12} md={3}>
                        <div 
                            style = {{
                                display : "flex",
                                justifyContent : "right",
                                gap : 8
                            }}
                        >
                            <IconButton 
                                onClick = { () => goTo( "/massiveWaybills" ) }
                                title = "Crear guia masiva"
                                sx = {{
                                    backgroundColor : "#079000",
                                    color : "#ffffff",
                                    width : "35px",
                                    height : "35px",
                                    borderRadius : 2,
                                    border : "2px solid #079000",
                                    "&:hover" : {
                                        backgroundColor : "#056a00",
                                        color : "#dcdcdc",
                                        border : "2px solid #056a00"
                                    }
                                }}
                            >
                                <LibraryAdd />
                            </IconButton>

                            <IconButton 
                                onClick = { () => goTo( "/quotes" ) }
                                title = "Crear una cotización"
                                sx = {{
                                    backgroundColor : "#ff4500",
                                    color : "#ffffff",
                                    width : "35px",
                                    height : "35px",
                                    borderRadius : 2,
                                    border : "2px solid #ff4500",
                                    "&:hover" : {
                                        backgroundColor : "#e93f01",
                                        color : "#dcdcdc",
                                        border : "2px solid #e93f01"
                                    }
                                }}
                            >
                                <RequestQuote />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            </Container>

            
            <TableContainer>
                <Table stickyHeader aria-label="Tabla con encabezados estáticos">

                    <TableHead>
                        <TableRow>
                            { tableCellsHead }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { waybillsTableRows }
                    </TableBody>
                </Table>
            </TableContainer>
            
        </Paper>

        <ConfirmationDialog 
            open = { open }
            handleClose = { handleClose }
            waybillNumber = { waybillNumber }
        />

    </Container>
);*/
import { Avatar, Container, FormControl, Grid, Paper, Stack, Typography, InputLabel, Select, MenuItem, Box, Alert, Button, Skeleton, Modal, Collapse, Card, CardContent, useTheme, List, ListItem, IconButton } from '@mui/material'
import Copyright from '../../General/Copyright'
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ChangePassword from './ChangePassword'
import { ErrorOutline, PersonOffOutlined, AddCard, SignalWifiConnectedNoInternet4, Replay } from '@mui/icons-material'
import { PrePaymentCard } from '../../Shared'
import GitInfo from 'react-git-info/macro'
import BalanceMovement from './BalanceMovement'

export default function Profile() {
  const localhost = sessionStorage.getItem('localhost')
  const token = sessionStorage.getItem('token')
  const userPermissions = JSON.parse( sessionStorage.getItem('permissions') )
  const navigation = useNavigate()

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [loadingSubsidiaries, setLoadingSubsidiaries] = useState(true)
  const [subsidiaries, setSubsidiaries] = useState([])
  const [changingSubsidiary, setChangingSubsidiary] = useState(false)
  const [errorChanging, setErrorChanging] = useState(false)
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false)
  const [loadingCostumerPrePayments, setLoadingCostumerPrePayments] = useState(true)
  const [costumerPrePayments, setCostumerPrePayments] = useState(undefined)

  const [loadingBalance, setLoadingBalance] = useState(true)
  const [hasBalanceAccess, setHasBalanceAccess] = useState(true)
  const [balance, setBalance] = useState(undefined)
  const [commitHash, setCommitHash] = useState(undefined)

  async function getProfile() {
    try {
      const request = await fetch(`${localhost}/api/v2/profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if (request.ok) {
        const response = await request.json()
        sessionStorage.setItem('user', JSON.stringify(response.data))
      }
    } catch (error) {
      setErrorChanging(true)
      setTimeout(() => {
        setErrorChanging(false)
      }, 5000)
      console.error('Get profile:', error)
    } finally {
      setUser(JSON.parse(sessionStorage.getItem('user')))
    }
  }

  async function getBalance() {
    try {
      setLoadingBalance(true)

      const request = await fetch(`${localhost}/api/v2/payment/credit`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if (request.ok) {
        const response = await request.json()
        setBalance(response.balance)
      } else {
        switch (request.status) {
          case 403:
            setHasBalanceAccess(false)
            setBalance(null)
            break

          default:
            throw new Error(`${request.status} - ${request.statusText}`)
        }
      }
    } catch (error) {
      console.error('Get balance:', error)
      setBalance(null)
    } finally {
      setLoadingBalance(false)
    }
  }

  async function getSubsidiaries() {
    try {
      setLoadingSubsidiaries(true)

      const request = await fetch(`${localhost}/api/v2/subsidiaries`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if (request.ok) {
        const response = await request.json()
        let allSubsidiaries = []

        response.subsidiaries.forEach(item => {
          allSubsidiaries.push({ value: item?.id, name: item?.name })
        })
        setSubsidiaries(allSubsidiaries)
      } else {
        // console.error('Get subsidiaries:', error)
      }
    } catch (error) {
      console.error('Get subsidiaries:', error)
    } finally {
      setLoadingSubsidiaries(false)
    }
  }

  async function patchSubsidiary(value) {
    try {
      setChangingSubsidiary(true)

      const request = await fetch(`${localhost}/api/v2/profile/change/subsidiary`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id_sucursal: value
        })
      })

      if (request.ok) {
      }
    } catch (error) {
      setErrorChanging(true)
      setTimeout(() => {
        setErrorChanging(false)
      }, 5000)
      console.error('Patch subsidiary:', error)
    } finally {
      getProfile()
      setChangingSubsidiary(false)
    }
  }

  async function getCostumerPrePayments() {
    try {
      setLoadingCostumerPrePayments(true)
      setCostumerPrePayments(undefined)

      const request = await fetch(`${localhost}/api/v2/costumerPrePayments`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache'
        }
      })

      if(request.ok){
        const response = await request.json()
        setCostumerPrePayments(response.costumerPrePayments)
        return
      }

      throw new Error('')
    } catch (error) {
      setCostumerPrePayments(null)
    } finally {
      setLoadingCostumerPrePayments(false)
    }
  }

  function onChangePasswordSuccess() {
    setShowChangePasswordModal(false)
    setChangePasswordSuccess(true)
    setTimeout(() => {
      setChangePasswordSuccess(false)
    }, 5000)
  }

  useEffect(() => {
    getSubsidiaries()
    getBalance()
    getCostumerPrePayments()

    const gitInfo = GitInfo()
    setCommitHash(gitInfo.commit.shortHash)
  }, [])

  return (
    <>
      <Container
        maxWidth='lg'
        sx={{ mt: 4, mb: 4, textAlign: 'left' }}
      >
        <Grid
          backgroundColor='default'
          container
          spacing={3}
        >
          {/* Formulario */}
          <Grid
            backgroundColor='default'
            item
            xs={12}
            md={5}
          >
            <Paper
              variant='outlined'
              sx={{ p: 2 }}
            >
              <Stack
                spacing={2}
                direction='column' //{ xs: 'row', md: 'column' }}
              >
                {/* Avatar */}
                <Box sx={{ width: 125, height: 125, alignItems: 'end' }}>
                  <Avatar
                    src={`https://drive.google.com/uc?export=view&id=${user?.id_foto}`}
                    sx={{ width: 125, height: 125 }}
                  />
                </Box>

                <Typography
                  variant='h1'
                  sx={{ textAlign: 'left' }}
                >
                  {user?.name}
                </Typography>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      variant='outlined'
                      onClick={() => setShowChangePasswordModal(true)}
                      fullWidth
                    >
                      Cambiar contraseña
                    </Button>

                    <Typography
                      variant='h5'
                      sx={{ textAlign: 'center', color:'#808080',fontSize:15, pt:2 }}
                    >
                      {commitHash}
                    </Typography>
                  </Grid>
                </Grid>

                <Collapse in={changePasswordSuccess}>
                  <Alert severity='success'>¡Listo! Tu contraseña ha sido actualizada</Alert>
                </Collapse>
              </Stack>
            </Paper>
          </Grid>

          {/* Secciones */}
          <Grid
            backgroundColor='default'
            item
            xs={12}
            md={7}
          >
            <Stack
              spacing={2}
              direction='column'
            >
              {['Cliente', 'CompanyAdmin'].findIndex(r => r === userPermissions['role']) === -1 && (
                  <Section
                    title='Sucursal de origen'
                    icon={<StoreMallDirectoryOutlinedIcon sx={{ fontSize: 18 }} />}
                  >
                    <Subsidiary
                      loading={loadingSubsidiaries}
                      error={errorChanging}
                      changing={changingSubsidiary}
                      subsidiaries={subsidiaries}
                      subsidiary={user?.id_sucursal}
                      patchSubsidiary={patchSubsidiary}
                    />
                  </Section>
              )}
              {['Admin', 'PDV', 'Supervisor', 'CompanyAdmin', 'Cliente'].includes(userPermissions['role']) && (
                <Section
                  title='Saldo disponible'
                  icon={<AccountBalanceWalletOutlinedIcon sx={{ fontSize: 18 }} />}
                >
                  <Credit
                    loading={loadingBalance}
                    balance={balance}
                    hasBalanceAccess={hasBalanceAccess}
                    getBalance={getBalance}
                  />

                  <PrePayments 
                    icon={<AddCard sx={{ fontSize: 18 }}/>}
                    title={'Paquetes contratados'}
                    prePayments={costumerPrePayments}
                    loading={loadingCostumerPrePayments}
                    reloading={getCostumerPrePayments}
                  />

                  {
                    loadingBalance || loadingCostumerPrePayments ?
                      <Stack
                        direction='row'
                        justifyContent='end'
                      >
                        <Skeleton
                          variant='rectangular'
                          width={150}
                          height={40}
                        />
                      </Stack>
                    :
                      <Stack
                        direction='row'
                        justifyContent='end'
                      >
                        <Button
                          variant='outlined'
                          onClick={() => navigation('/addCredits')}
                        >
                          <AccountBalanceWalletOutlinedIcon sx={{ fontSize: 18 }} />Agregar saldo/<AddCard sx={{ fontSize: 18 }} />Contratar { costumerPrePayments === null || costumerPrePayments.length !== 0 ? 'otro' : 'primer' } paquete
                        </Button>
                      </Stack>
                  }
                </Section>
              )}
            </Stack>
            {/*<BalanceMovement></BalanceMovement>*/}
          </Grid>
          <BalanceMovement></BalanceMovement>
        </Grid>
        

        {/* Footer */}

        <Grid
          container
          paddingTop={2}
        >
          <Grid
            item
            xs={12}
          >
            <Paper
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 50
              }}
            >
              <Copyright sx={{ pt: 4 }} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(!showChangePasswordModal)}
      >
        <ChangePassword
          close={() => setShowChangePasswordModal(!showChangePasswordModal)}
          success={() => onChangePasswordSuccess()}
        />
      </Modal>
    </>
  )
}

const Section = ({ icon, title, children }) => {
  const theme = useTheme()

  return (
    <Card
      variant='outlined'
      sx={{ height: '100%', maxWidth: '100%'}}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            alignContent='center'
          >
            <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
            <Typography variant='subtitle1'>{title}</Typography>
          </Stack>

          {children}
        </Stack>
      </CardContent>
    </Card>
  )
}

const Subsidiary = ({ loading, error, changing, subsidiaries, subsidiary, patchSubsidiary }) => {
  if (loading === true) {
    return (
      <Stack spacing={2}>
        <Skeleton
          variant='text'
          width={350}
        />
        <Skeleton
          variant='rectangular'
          height={40}
        />
      </Stack>
    )
  }

  if (subsidiaries?.length > 0) {
    return (
      <Stack spacing={2}>
        <Typography
          variant='body1'
          sx={{ textAlign: 'left' }}
        >
          A continuación, se muestra la sucursal a la que perteneces, puedes modificarla seleccionando una nueva
        </Typography>
        <FormControl
          fullWidth
          size='small'
        >
          <InputLabel id='subsidiaries-label'>Sucursales</InputLabel>
          <Select
            labelId='subsidiaries-label'
            id='subsidiaries-selector'
            value={subsidiary}
            label='Sucursales'
            disabled={changing === true}
            onChange={selected => {
              patchSubsidiary(selected.target.value)
            }}
          >
            {subsidiaries.map(item => (
              <MenuItem
                key={item?.name}
                value={item?.value}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && <Alert severity='error'>Ocurrió un error al actualizar tu sucursal, inténtalo nuevamente</Alert>}
      </Stack>
    )
  }

  return null
}

const Credit = ({ loading, balance, hasBalanceAccess, getBalance }) => {
  const theme = useTheme()

  if (loading) {
    return (
      <Stack>
        <Skeleton
          variant='text'
          width={350}
        />
        <Skeleton
          variant='text'
          width={150}
          height={75}
        />
      </Stack>
    )
  }

  if (balance === null && hasBalanceAccess === false)
    return (
      <Stack spacing={2}>
        <Stack
          direction='row'
          spacing={2}
        >
          <PersonOffOutlined sx={{ fontSize: 50 }} />
          <Stack>
            <Typography variant='subtitle1'>Sin acceso</Typography>
            <Typography variant='body1'>Tu empresa está deshabilitada, contacta a soporte técnico para más detalles</Typography>
          </Stack>
        </Stack>
      </Stack>
    )

  if (balance !== undefined && balance !== null) {
    return (
      <Stack
        textAlign='center'
      >
        <Typography
          variant='body1'
          sx={{ fontSize: 34 }}
        >
          ${balance}
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{ p: 2 }}
    >
      <Stack
        direction='row'
        spacing={2}
      >
        <ErrorOutline sx={{ fontSize: 50 }} />
        <Stack>
          <Typography variant='subtitle1'>Ocurrió un error</Typography>

          <Stack 
            direction='row'
            alignItems='center'
          >
            <Typography variant='body1'>No pudimos recuperar tu saldo, inténtalo nuevamente</Typography>
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#ffffff'
              }}
              title='Volver a intentar'
              onClick={() => getBalance()}
            >
              <Replay sx={{ fontSize: 18 }} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

const PrePayments = ({ icon, loading, reloading, title, prePayments }) => {
  const theme = useTheme()

  return (
    <Stack>
      <Stack spacing={1}>
        <Stack
          direction='row'
          spacing={1}
          alignContent='center'
        >
          <Avatar sx={{ width: 28, height: 28, backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
          <Typography variant='subtitle1'>{title}</Typography>
        </Stack>

        {
          loading ?
            <Stack
              maxWidth='100%'
              overflow='auto'
            >
              <List
                sx={{
                  display: 'flex',
                  gap: 0.2
                }}
              >
                {
                  [1,2,3].map(() => (
                      <ListItem>
                        <Skeleton 
                          variant='rectangular'
                          width={210}
                          height={243}
                        />
                      </ListItem>
                    )
                  )
                }
              </List>
            </Stack>
          : 
            <Stack
              maxWidth='100%'
              direction={prePayments === null || prePayments.length === 0 ? 'column' : 'row'}
              overflow={prePayments !== null && prePayments.length !== 0 ? 'auto' : 'inherit'}
              spacing={prePayments === null || prePayments.length === 0 ? 1 : 0}
            >
              {
                prePayments === null ?
                  <Stack 
                    direction='row'
                    alignItems='center'
                  >
                    <Typography 
                      variant='body1'
                      alignItems='center'
                    >
                      <SignalWifiConnectedNoInternet4 sx={{ fontSize: 18 }} />No pudimos recuperar sus paquetes contratados, inténtalo nuevamente. 
                    </Typography>
                    <IconButton
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff'
                      }}
                      title='Volver a intentar'
                      onClick={() => reloading()}
                    >
                      <Replay sx={{ fontSize: 18 }}/>
                    </IconButton>
                  </Stack>
                :
                  prePayments.length !== 0 ?
                    <List
                      sx={{
                        display: 'flex',
                        gap: 0.2
                      }}
                    >
                      {
                        prePayments.map(prePayment => (
                          <ListItem>
                            <PrePaymentCard 
                              data={prePayment}
                            />
                          </ListItem>
                        ))
                      }
                    </List>
                  : <Typography variant='body1'>Vaya al parecer no a contratado un paquete, comienza ahorra contratando un paquete</Typography>
              }
            </Stack>
        }
      </Stack>
    </Stack>
  )
}